import React, { Component } from 'react';
import { compose } from 'recompose';
import { Button, Header, Grid, Segment } from 'semantic-ui-react';
import {Form, Input} from 'semantic-ui-react-form-validator';
import { withTranslation } from 'react-i18next';
import { withAuthorization } from '../Session';

class ProbabilityCalculator extends Component{
	constructor(props) {
        super(props);

        this.state = {
			values: {},
			result: 0
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        //this.unsubscribe && this.unsubscribe();
    }

	onHandleSubmit = event => {
  
        let data = this.state.values;
        console.log(data);
        var createRequestedUserMethod = this.props.firebase.functions.httpsCallable('pgm-probabilityCalculator');
        createRequestedUserMethod(data).then(result => {
            // Read result of the Cloud Function.
			console.log(result.data);
			this.setState({ result: result.data });
        }).catch(function(error) {
            // Getting the Error details.
            console.log(error);
        });
    };

    handleChange = (event,data) => {
        const newFields = { ...this.state.values, [data.name]: data.value };
        this.setState({ values: newFields });

        //this.setState({ [event.target.name]: event.target.value });
    };

    render(){
		const {t} = this.props;
		const {result, values} = this.state;
        return(
			<React.Fragment>
				<Header as="h1" color="teal" dividing>{t("probabilityCalculator")}</Header>
				<Form id="dataForm" onSubmit={this.onHandleSubmit} >

                    <Segment basic>{t("probability_calculator_introduction")}</Segment>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={3}><Input type="text" label={t("possiblities")} placeholder={t("possiblities")} name="n" onChange={this.handleChange} value={values.n} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} width={16} /> </Grid.Column>
                            <Grid.Column width={3}><Input type="text" label={t("repetitions")} placeholder={t("repetitions")} name="r" onChange={this.handleChange} value={values.r} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} width={16} /> </Grid.Column>
                            <Grid.Column width={3}><Input type="text" label={t("correct")} placeholder={t("correct")} name="c" onChange={this.handleChange} value={values.c} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} width={16} /> </Grid.Column>
                            <Grid.Column width={3}><Input type="text" label={t("result")} placeholder={t("result")} name="result" value={result.toFixed(5)} width={16} disabled={true} /> </Grid.Column>
                            <Grid.Column width={3} verticalAlign="bottom"><Button color='teal' type="submit">{t("calculate")} </Button> </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Segment basic>{t("probability_calculator_explanation")}</Segment>

				</Form>
			</React.Fragment>
			
        )
    }
}
const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withTranslation(),
)(ProbabilityCalculator);
