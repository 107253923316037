import { defaultDatabase } from 'firebase-functions/lib/providers/firestore';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.geoPoint = app.firestore.GeoPoint

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();

    this.db.settings({
      cacheSizeBytes: app.firestore.CACHE_SIZE_UNLIMITED
    });
    
    this.db.enablePersistence()

    


    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  reauthenticate = (currentPassword) => {
    var cred = this.emailAuthProvider.credential(this.auth.currentUser.email, currentPassword);
    return this.auth.currentUser.reauthenticateWithCredential(cred);
  };

  changePassword = (currentPassword, newPassword) => {
    return this.reauthenticate(currentPassword)
    .then(() => {
      var user = this.auth.currentUser;
      return user.updatePassword(newPassword);
    }).then(() => {
        console.log("Password updated!");
        return { status: "Success", message: "Password updated!"};
    });
  }

  
  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            //console.log(dbUser);

            // default empty roles
            if (!dbUser.hasOwnProperty("roles") ) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              //emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
            //console.log(authUser);
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.doc(`Users/${uid}`);
  users = () => this.db.collection('Users');

  group = groupId => this.db.doc(`User/${groupId}`);
  groups = () => this.db.collection('UserGroups');

  patient = patientId => this.db.doc(`Patients/${patientId}`);
  patients = () => this.db.collection(`Patients`);

  package = packageId => this.db.doc(`Packages/${packageId}`);
  packages = () => this.db.collection(`Packages`);

  rdsParameter = parameterId => this.db.doc(`RDSParameters/${parameterId}`);
  rdsParameters = () => this.db.collection(`RDSParameters`);

  parameterSet = setId => this.db.doc(`ParameterList/${setId}`);
  parameterSets = () => this.db.collection(`ParameterList`);

  invitedUsers = () => this.db.collection(`InvitedUsers`);
  invitedUser = invitationId => this.db.doc(`InvitedUsers/${invitationId}`);

  paymentRequests = () => this.db.collection('PaymentRequests');
  paymentRequest = purchaseTransactionId => this.db.doc(`PaymentRequests/${purchaseTransactionId}`);

  handleCreateInvittedUserAccount = () => this.functions.httpsCallable('handleCreateInvittedUserAccount')

  getGeoPoint = (latitude, longitude) => { return new app.firestore.GeoPoint(latitude,longitude); };
}

export default Firebase;
