import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {Table, Button, Icon } from 'semantic-ui-react';
import i18n from "../../i18n";

const TextField = (props) => {
    return <div>{props.name}</div>;
}

const GridActions  = (props) => {
    return "";
}
const GridButton  = (props) => {
    return "";
}

const GridField  = (props) => {
    return "";
}

const PrimaryButton = (props) => {
    return <Button as={Link} {...props} />
}


class Datagrid extends Component
{
    constructor(props){
        super(props);
    }

    renderHeaderRow() {
        return (
            <Table.Row>
                { this.props.hasOwnProperty("indexed") ? <Table.HeaderCell>#</Table.HeaderCell> : ""}
                {this.props.children.map((child,i) =>{
                    return <Table.HeaderCell key={i}>{this.getHeaderValue(child.props)}</Table.HeaderCell>
                })}
            </Table.Row>
        );
    }

    renderFooterRow() {
        const columns = this.props.hasOwnProperty("indexed") ? this.props.children.length + 1 : this.props.children.length;
        return (
            <Table.Row>
                <Table.HeaderCell colSpan={columns}></Table.HeaderCell>
            </Table.Row>
        );
    }

    renderBody() {
        return (
            <Table.Body>
                {
                    (this.props.data.length > 0) ? (
                        this.props.data.map((item,i) =>{
                            return this.renderDataRow(item, i);
                        })
                    ):(
                       this.renderEmptyDataRow()
                    )
                }
            </Table.Body>
        );
    }

    renderDataRow(record,i) {
        const rowKey = this.props.hasOwnProperty("uid") ? record[this.props.uid] : i;
        let selection  = {};
        if(this.props.hasOwnProperty("rowSelection"))
        {
            const rules = this.props.rowSelection;
            for(let item of rules)
            {
                const rule = item.rule;
                if( record[rule[0]] === rule[1])
                    selection = item.selection;
            }
        }
        return (          
            <Table.Row key={rowKey} {...selection} >
                { this.props.hasOwnProperty("indexed") ? <Table.Cell>{i+1}</Table.Cell> : ""}
                {this.props.children.map((child,j) =>{
                    return <Table.Cell key={j}>{this.getRecordValue(record,child)}</Table.Cell>
                })}
            </Table.Row>
        );
    }

    renderEmptyDataRow(){
        return (
            <Table.Row>
                <Table.Cell colSpan={this.props.children.length}>{this.props.emptyDataMsg}</Table.Cell>
            </Table.Row>
        );
    }

    getHeaderValue(childProps) {
        if(childProps.hasOwnProperty("label"))
        {
            return childProps["label"];
        }

        if(childProps.hasOwnProperty("name"))
        {
            return childProps["name"];
        }
        return "";
    }

    getRecordValue(record,element) {
        if( element.props.label === "Actions")
        {
            return this.renderActions(record,element);
        }
        else
        {
            if(record.hasOwnProperty(element.props.name))
            {
                if(element.props.hasOwnProperty("type"))
                {
                    if(element.props.type === "bool")
                    {
                        const boolValue = element.props.hasOwnProperty("value") ? element.props.value : true;
                        if(record[element.props.name] === boolValue)
                        {                        
                            if(boolValue === true)
                                return <Icon color='green' name='checkmark' size='large' />
                            else if(boolValue === false)
                                return <Icon color='red' name='delete' size='large' />
                        }
                    }
                    else if(element.props.type === "color")
                    {
                        if(record[element.props.name])
                        {
                            return record[element.props.name].R + " " + record[element.props.name].G + " " + record[element.props.name].B;
                        }
                    }
                    else if(element.props.type === "date")
                    {
                        if(record[element.props.name])
                        {
                            if(element.props.hasOwnProperty("language"))
                            {
                                return record[element.props.name].toDate().toLocaleDateString(element.props.language);
                            }
                            else
                            {
                                return record[element.props.name].toDate().toString();
                            }
                        }
                    }
                    else if(element.props.type === "url")
                    {
                        if(record[element.props.name])
                        {
                            let params = {};
                            if(element.props.hasOwnProperty("params"))
                            {
                                params = element.props.params;
                                return <a href={record[element.props.name]} {...params}>{element.props.label}</a>;
                            }
                            else
                            {
                                return <a href={record[element.props.name]}>{element.props.label}</a>;
                            }
                        }
                    }

                }
                return record[element.props.name];
            }
        }
        
        return "";
    }

    renderActions(record,element) {
        if(Array.isArray(element.props.children))
        {
            return element.props.children.map(child => {
                return this.getActionButton(record,child);
            });
        }
        else {
            const child = element.props.children;
            return this.getActionButton(record,child);
        }
    }

    getActionButton( record, element ) {
        let pathName = element.props.path;
        if( element.props.hasOwnProperty("params"))
        {
            let paramsObject = element.props.params;
            Object.keys(paramsObject).forEach( key => {
                if( paramsObject.hasOwnProperty(key))
                {
                    if( record.hasOwnProperty(paramsObject[key]))
                    {
                        pathName = pathName.replace(":"+key,record[paramsObject[key]]);
                    }
                }
            });
        }
        
        let pathObject = { 
            pathname: pathName,
        };
        if( element.props.hasOwnProperty("state"))
        {
            pathObject.state = {[element.props.state] : record};
        }
        return (<Button as={Link} to={pathObject} {...element.props}>{element.props.title}</Button>);
    }

    render() {
        return (
            <Table striped>
                <Table.Header>
                    {this.renderHeaderRow()}
                </Table.Header>
                {this.renderBody()}
                <Table.Footer>
                    {this.renderFooterRow()}
                </Table.Footer>  
            </Table>
        );
    }
}

export { GridField,GridButton,GridActions, Datagrid };
export default Datagrid;