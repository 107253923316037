import React from 'react';
import { toast } from 'react-semantic-toasts';



const message = ( msgType, msgTitle, msgContent, duration ) => {
	toast(
		{
			title: msgTitle,
			description: <p dangerouslySetInnerHTML={{ __html: msgContent }}></p>,
			time: duration,
			type: msgType
		});
}

const success = (msg) =>{
	message("success","Success",msg);
}

const error = (msg, duration = 5) =>{
	message("error","Error",msg, duration * 1000);
}

const warrning = (msg) =>{
	message("warning","Warning",msg);
}

const info = (msg) =>{
	message("info","Info",msg);
}

export { message, success,info, error, warrning} ;