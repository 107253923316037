import React from 'react';

import { withFirebase } from '../Firebase';

import { withTranslation  } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

const SignOutButton = ({ t,firebase }) => (
    <Dropdown.Item onClick={firebase.doSignOut}>{t("logout")}</Dropdown.Item>
);

export default withTranslation()(withFirebase(SignOutButton));
