import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import GroupContext from './groupContext';
import withAuthorization from './withAuthorization';

const withGroup = condition => Component => {
	class WithGroup extends React.Component {
		componentDidMount() {
		}

		componentWillUnmount() {
		}

		render() {
			return (
				<GroupContext.Consumer>
					{groupContext => {
						console.log(groupContext.workgroup);
						return <Component {...this.props} workgroup={groupContext.workgroup} />;
					} 
					}
				</GroupContext.Consumer>
			);
		}
	}

	return compose(
		withRouter,
		withAuthorization(condition),
		)(WithGroup);
	};

export default withGroup;
