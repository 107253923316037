import React, { Component } from 'react';
import {Form, Input, Dropdown} from 'semantic-ui-react-form-validator';
import { withTranslation } from 'react-i18next';

class UserDataForm extends Component{
    render(){
        const {t, values } = this.props;
        return(
            <Form id="dataForm" onSubmit={this.props.nextStep} >
                <h2>{t("user_data")}</h2>
                <Input type="text" label={t("buyersName")} placeholder={t("buyersName")} name="purchase_name" onChange={this.props.handleChange} value={values.purchase_name} validators={['required']} errorMessages={['this field is required']} width={8} /> 
                <Input floated='left' type="text" label={t("email")} placeholder={t("email")} name="purchase_email" onChange={this.props.handleChange} value={values.purchase_email} validators={['required','isEmail']} errorMessages={['this field is required','Should be email']} width={8} disabled={true} />
                
                <Dropdown
                    type="dropdown"
                    label={t("groupName")}
                    name='purchase_group'
                    placeholder={t("chooseGroup")}
                    search
                    selection
                    allowAdditions
                    validators={['required']} 
                    errorMessages={['this field is required']}
                    additionLabel={t("groupName") + ": "}
                    options={values.groupOptions}
                    value={values.purchase_group}
                    onAddItem={this.props.handleGroupAddition}
                    onChange={this.props.handleChange}
                    width={8}
                />
            </Form>
        )
    }
}

export default withTranslation()(UserDataForm);