import React, { Component } from 'react';
import { Checkbox, Segment, List, Grid, Divider } from 'semantic-ui-react';
import {Form} from 'semantic-ui-react-form-validator';
import { withTranslation } from 'react-i18next';
import {  GetCountryName, GetRegionName} from '../Elements/CountryDropdown';
import * as BuyerTypes from '../../constants/buyer_types';

class SummaryAndConfirmForm extends Component{

    forwardedFields()
    {
        const {t, values } = this.props
        const fieldArray = ["name", "email","company_name","country","region","city","postalCode","address"];
        let forwardedFields = "";
        let first = true;
        for(let field of fieldArray)
        {
            if(field === "company_name" && values.purchase_buyer_type === BuyerTypes.COMPANY )
                forwardedFields += (!first ? ", ": "") + t(field);
            else
                forwardedFields += (!first ? ", ": "") + t(field);
            if( first )
                first = false;
        }
        return forwardedFields;
    }

    renderPrices() {
        const {t } = this.props
        const prices = this.props.handlePrices();
        return <React.Fragment>
            <div>{t("unit_price")}: {prices.unitPrice.toFixed(2)} €</div>
            <div>{t("vat")}: {prices.vat.toFixed(2)} %</div>
            <div>{t("vat_price")}: {prices.vatPrice.toFixed(2)} €</div>
            <Divider />
            <div><b>{t("total_price")}: {prices.totalPrice.toFixed(2)} €</b></div></React.Fragment>;
    }

    render(){
        const {t,i18n, values } = this.props
        return(
        <Form id="confirmForm" >
            <h1>{t("summary_and_confirm")}</h1>

            <Segment>
                <h2>{t("package_data")}</h2>
                <Grid>
                    <Grid.Row>
                        <Grid.Column as='a' width={4}>{values.packageItem.name}</Grid.Column>
                        <Grid.Column width={8}>{values.packageItem.description}</Grid.Column>
                        <Grid.Column width={4} textAlign="right">
                            {this.renderPrices()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment>
                <h2>{t("invoice_data")}</h2>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <h3>{t("user_data")}</h3>
                            <List>
                                <List.Item><b>{t("name")}: </b>{values.purchase_name} </List.Item>
                                <List.Item><b>{t("email")}: </b>{values.purchase_email} </List.Item>
                                <List.Item><b>{t("groupName")}: </b>{values.groupOptions.find(x => x.value === values.purchase_group).text} </List.Item>
                            </List>
                        </Grid.Column>
                        {values.purchase_buyer_type === BuyerTypes.COMPANY ? (
                        <Grid.Column width={6}>
                            <h3>{t("company_data")}</h3>
                            <List>
                                <List.Item><b>{t("company_name")}: </b>{values.company_name} </List.Item>
                                <List.Item><b>{t("vat_number")}: </b>{values.vat_number} </List.Item>
                                <List.Item><b>{t("registration_number")}: </b>{values.registration_number} </List.Item>
                                <List.Item><b>{t("official_represented_by")}: </b>{values.official_represented_by},{values.official_representer_role} </List.Item>
                                <List.Item><b>{t("company_email")}: </b>{values.company_email} </List.Item>
                                <List.Item><b>{t("iban")}: </b>{values.iban} </List.Item>
                            </List>
                        </Grid.Column>
                        ) : ("")}
                        <Grid.Column width={5}>
                            <h3>{t("invoice_address")}</h3>
                            <List>
                                <List.Item><b>{t("country")}: </b>{GetCountryName(values.country)} </List.Item>
                                <List.Item><b>{t("region")}: </b>{GetRegionName(values.country,values.region)} </List.Item>
                                <List.Item><b>{t("postalCode")}: </b>{values.postalCode} </List.Item>
                                <List.Item><b>{t("city")}: </b>{values.city} </List.Item>
                                <List.Item><b>{t("address")}: </b>{values.street} </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment>
                {t("text_values_are_correct_completed")}
                <br/><br/>            
                <Checkbox toggle name="correct_verified_value" onChange={this.props.handleChange} label={t("correct_completed_values")} checked={values.correct_verified_value} />
            </Segment>
            <Segment>
                { i18n.language === "hu" ? (
                    <div>
                        Tudomásul veszem, hogy a(z) EUVISION Szolgáltató KFT. (Hungary 7623 Pécs Semmelweis utca 32. 3. em.) adatkezelő által a(z) https://tab.euvision.hu felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. 
                        Az adatkezelő által továbbított adatok köre az alábbi: {this.forwardedFields()}.
                        Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:
                        <a href="http://simplepay.hu/vasarlo-aff" target="_blank" rel="noopener noreferrer" >http://simplepay.hu/vasarlo-aff</a>
                    </div>
                ): (
                    <div>
                        I acknowledge the following personal data stored in the user account of EUVISION Szolgáltató KFT. (Hungary 7623 Pécs Semmelweis utca 32. 3. em.) 
                        in the user database of https://tab.euvision.hu will be handed over to OTP Mobil Ltd. and is trusted as data processor. 
                        The data transferred by the data controller are the following: {this.forwardedFields()}. 
                        The nature and purpose of the data processing activity performed by the data processor in the SimplePay Privacy Policy can be found at the following link:
                        <a href="http://simplepay.hu/vasarlo-aff" target="_blank" rel="noopener noreferrer" >http://simplepay.hu/vasarlo-aff</a>
                    </div>
                ) }
                
                <br/>
                <Checkbox toggle name="termsAgree" onChange={this.props.handleChange} label={t("accept_terms_conditions")} checked={values.termsAgree} />
            </Segment>
        </Form>
        )
    }
}

export default withTranslation()(SummaryAndConfirmForm);