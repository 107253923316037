import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../../../components/Session';
import * as ADMIN_ROUTES from './../admin_routes';

import { Loader} from 'semantic-ui-react';
import {Datagrid, GridField, GridActions, GridButton} from '../../../components/Elements/Datagrid';
import ExportExcel from '../../../components/Elements/ExcelExport';

import {rdsParameterColumns} from '../RDSParameters/columns';
import RDSParameterImport from '../RDSParameters/RDSParameterImport';

class RDSParameterList extends Component {
	columns =[];

	constructor(props) {
		super(props);

		this.columns = rdsParameterColumns;
		this.state = {
			loading: false,
			rdsParameters: [],
		};
	}

	componentDidMount() {
		this.setState({ loading: true });
		
		this.getItems();
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	getItems( ) {
		this.setState({ loading: true });
		let rdsParmeterQuery = this.props.firebase.rdsParameters();
		if(this.props.setId)
		{
			//rdsParmeterQuery = rdsParmeterQuery.where("setNameRef","==",this.props.firebase.parameterSets().doc(this.props.setId));
			rdsParmeterQuery = rdsParmeterQuery.where("parameterSetId","==",this.props.setId).orderBy("orderId","asc");
		}

		this.unsubscribe = rdsParmeterQuery
			.onSnapshot(snapshot => {
				let rdsParameters = [];

				snapshot.forEach(doc => {
					let data = this.convertToTableData(doc.data(),["setNameRef"]);

					rdsParameters.push({
						...data, 
						uid: doc.id,
					});
				});

				this.setState({
					rdsParameters,
					loading: false
				});
			});
	}

	convertToTableData( docObject , excludeProperties = [] ){
		let convertedOnject = {};
		for(let property of Object.getOwnPropertyNames(docObject))
		{
			if( docObject.hasOwnProperty(property) )
			{
				if( !excludeProperties.includes(property))
				{
					convertedOnject[property] = docObject[property];
				}	
			}
		}
		return convertedOnject;
	}

	getFileName(){
		if(this.props.setName)
		{
			return this.props.setName.replace(" ","_") + ".xlsx";
		}
		else
		{
			return "allRDSParameters.xlsx";
		}
	}

	render() {
		const { rdsParameters, loading } = this.state;
		const exportFileName = this.getFileName();

		let gridColumns = this.columns.map( (property,i) => (
			<GridField name={property.name} type={property.type} />
		));
		gridColumns.push(
			<GridActions label="Actions">
				<GridButton path={ADMIN_ROUTES.RDS_PARAMETER_EDIT} params={{id:"uid"}} title="Edit" state="rdsParameterItem" positive={true} />
			</GridActions>
		);

		return (
			<div>
			{loading || this.columns.length === 0 ? (
				<Loader active inline />
			) : (
				<React.Fragment>
					{
						rdsParameters.length === 0 ? <RDSParameterImport setId={this.props.setId} /> : ""
					}
					<ExportExcel fileName={exportFileName} data={rdsParameters} columns={this.columns}  />
					<Datagrid data={rdsParameters} uid="uid">
						{gridColumns}					
					</Datagrid>
				</React.Fragment>
			)}
			</div>
		);
	}
}

const condition = authUser => !!authUser;

export default compose(
	withAuthorization(condition),
  )(RDSParameterList);
