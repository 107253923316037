import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Button, Header, Segment, Loader } from 'semantic-ui-react';
import { withFirebase } from '../Firebase';

import {Form, Input } from 'semantic-ui-react-form-validator';
import * as ROUTES from '../../constants/routes';
import * as alertModal from "../Elements/AlertMsg";
import * as utilName from '../Util/names';
import i18n from "../../i18n";


 

const INITIAL_STATE = {
    name: '',
    email: '',
    password: '',
    phone: '',
    groupName: '',
    error: null,
    paymentRequest: null,
    loading: true
};

class RequestRegistration extends Component {
    constructor(props) {
        super(props);

        this.paymentRequestRef = this.props.firebase.paymentRequests();
        this.state = { ...INITIAL_STATE, ...props.location.state };
    }

    componentDidMount() {
        const { paymentRequestId } = this.state;
        this.setState({ loading: true });
        this.unsubscribe = this.props.firebase
            .paymentRequest(paymentRequestId)
            .onSnapshot(snapshot => {
                console.log(snapshot.data());
                this.setState({
                    purchaseId: snapshot.id,
                    paymentRequest: snapshot.data(),
                    name: snapshot.data().user.name, 
                    email: snapshot.data().email, 
                    groupName: snapshot.data().group.name,
                    password: '',
                    repeatPassword: '',
                    loading: false,
                });
            });
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onHandleSubmit = event => {
        const { history , t } = this.props;

        let data = this.prepareData();
        this.setState({ loading: true });
        var createRequestedUserMethod = this.props.firebase.functions.httpsCallable('user-createRequestedUserAccount');
        createRequestedUserMethod(data).then(result => {
            // Read result of the Cloud Function.
            if(result.data.code === 200)
            {
                const redirectCallback = () => { history.push(ROUTES.SIGN_IN)};
                alertModal.successAlert(t("title_success"),t("msg_account_successfully_created"),redirectCallback);
            }
            else
            {
                alertModal.successAlert(t("title_error"),t("msg_error_on_registration"));
            }
            this.setState({ loading: false });
            //console.log(result);
        }).catch(function(error) {
            // Getting the Error details.
            console.log(error);
            alertModal.errorAlert(t("title_error"),t("msg_error_on_registration"));
            this.setState({ loading: false });
        });
    };

    prepareData = () => {
        const { paymentRequest, name, email,groupName, password, phone, purchaseId } = this.state;
        let dataItem = paymentRequest;
        
        dataItem['authData'] = {
            email: email,
            emailVerified: false,
            password: password,
            displayName: name
        };
        if (phone > 0){
            dataItem['authData'].phone = phone
        }

        dataItem['userData'] = {
            fullName: name,
            phone : {
                mobile : phone,
                fix: ''
            },
            registrationDate : new Date().toISOString(),
            name: utilName.getNames(name,(i18n.language === "hu" ? 'LFM' : 'FLM') )
        }
        dataItem['group'].name = groupName;
        /*
        dataItem['purchaseData'] = {
            isActive : true,
            isRefund : false,
            user : {
                name: name,
            },
            group : {
                name: groupName,
            },
            package : {
                id: paymentRequest.packageData.id,
                name: paymentRequest.packageData.name,
            },
            paymentType : "SimplePay",
            simplePay: paymentRequest.simplePay,
            totalSalePrice: paymentRequest.simplePay.total,
            quantity: 1
        };*/
        dataItem.purchaseId = purchaseId;


        return dataItem;
    }


    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { t } = this.props;
        const { name, email,groupName, loading, password, repeatPassword } = this.state;

        const isInvalid = password === '' || (password !== repeatPassword);
        
        return (
            <div>
                <Header as="h1" color="teal" dividing>{t("requestRegistration")}</Header>
                    <Segment loading={loading}>
                        <Form onSubmit={this.onHandleSubmit}>
                            <Input type="text" label={t("name")} placeholder={t("name")} name="name" onChange={this.handleChange} value={name} validators={['required']} errorMessages={['this field is required']} width={4} /> 
                            <Input type="text" label={t("email")} placeholder={t("email")} name="email" onChange={this.handleChange} value={email} validators={['required','isEmail']} errorMessages={['this field is required','Should be email']} width={4} disabled={true} />
                            <Input type="text" label={t("groupName")} placeholder={t("groupName")} name="groupName" onChange={this.handleChange} value={groupName} validators={['required']} errorMessages={['this field is required']} width={4} />
                            <Input type="password" label={t("password")} placeholder={t("password")} name="password" onChange={this.handleChange} value={password} validators={['required','matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$']} errorMessages={['this field is required','Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (#?!@$%^&*-)']} width={4} /> 
                            <Input type="password" label={t("repeatPassword")} placeholder={t("repeatPassword")} name="repeatPassword" onChange={this.handleChange} value={repeatPassword} validators={['required']} errorMessages={['this field is required']} width={4} />
                            <Button color='teal' disabled={isInvalid} type="submit">{t("register")}</Button>
                        </Form>
                    </Segment>
        </div>
        );
  }
}

export default withTranslation()(withFirebase(RequestRegistration));
