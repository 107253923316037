import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
import Workbook from 'react-xlsx-workbook';


const ExportExcel = ({fileName,columns, data, buttonName="Export", sheetName="Sheet 1"}) => {

	if (data.length > 0)
	{
		return <Workbook filename={fileName} element={<Button primary >{buttonName}</Button>}>
			<Workbook.Sheet data={data} name={sheetName} >
				{
					columns.map( (property,i) => {
						if(property.type === "color")
							return <Workbook.Column label={property.name} value={record => record[property.name].R + " " + record[property.name].G + " " + record[property.name].B} />
						else
							return <Workbook.Column label={property.name} value={record => record[property.name].toString() } />
					})
				}
			</Workbook.Sheet>
		</Workbook>;
	}
	return "";
	
};

export default ExportExcel;



