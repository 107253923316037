import React from 'react';
import GroupContext from '../Session/groupContext';

import { Dropdown } from 'semantic-ui-react';

class GroupNavigation extends React.Component {

	constructor(props) {
		super(props);

		let groupOptions = [];
		for(let item of props.authUser.groups)
		{
			groupOptions.push({
				key : item.id,
				value : item.id,
				text: item.name        
			});
		}
		const group = { id: groupOptions[0].value, name: groupOptions[0].text};
		
        this.state = {
			workgroup : group,
			groupOptions: groupOptions
		};
    }

	handleOnChangeGroup = (e, data, groupContext) => {
		const groupName = data.options.find(x => x.value === data.value).text;
		const group = { id: data.value, name: groupName};
		this.setState({
			workgroup: group
		});
		groupContext.changeWorkgroup(data.value);
	}

	render() {
		const { workgroup, groupOptions} = this.state;
		return (
			<GroupContext.Consumer>
				{groupContext => 
						<Dropdown options={groupOptions} className='item' onChange={(e,data) => this.handleOnChangeGroup(e, data,groupContext)} defaultValue={workgroup.id} /> }
			</GroupContext.Consumer>
		);
	}
}

export default GroupNavigation;

//https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component