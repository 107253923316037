import React, { Component } from 'react';
import { Radio,Segment, Grid } from 'semantic-ui-react';
import {Form, Input} from 'semantic-ui-react-form-validator';
import { withTranslation } from 'react-i18next';
import { CountryDropdown,RegionDropdown } from '../Elements/CountryDropdown';
import * as BuyerTypes from '../../constants/buyer_types';

class InvoiceDataForm extends Component{
    render()
    {
        const { t,values } = this.props
        console.log(values.purchase_buyer_type);
        return(
        <Form id="dataForm" onSubmit={this.props.nextStep} >
            <h2>{t("enter_invoice_data")}</h2>
            <Segment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Radio toggle label={t("person_type")} name='purchase_buyer_type' value={BuyerTypes.PERSON} checked={values.purchase_buyer_type === BuyerTypes.PERSON} onChange={this.props.handleChange} />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Radio toggle label={t("company_type")} name='purchase_buyer_type' value={BuyerTypes.COMPANY} checked={values.purchase_buyer_type === BuyerTypes.COMPANY} onChange={this.props.handleChange} />									
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            {values.purchase_buyer_type === BuyerTypes.COMPANY ? (
            <Segment>
                <h3>{t("company_data")}</h3>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Input type="text" label={t("vat_number")} placeholder={t("vat_number")} name="vat_number" onChange={this.props.handleChange} value={values.vat_number} validators={['required']} errorMessages={['this field is required']} width={16} />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Input type="text" label={t("company_name")} placeholder={t("company_name")} name="company_name" onChange={this.props.handleChange} value={values.company_name} validators={['required']} errorMessages={['this field is required']} width={16} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Input type="text" label={t("registration_number")} placeholder={t("registration_number")} name="registration_number" onChange={this.props.handleChange} value={values.registration_number} validators={['required']} errorMessages={['this field is required']} width={16} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Input type="text" label={t("official_represented_by")} placeholder={t("official_represented_by")} name="official_represented_by" onChange={this.props.handleChange} value={values.official_represented_by} validators={['required']} errorMessages={['this field is required']} width={16} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Input type="text" label={t("official_representer_role")} placeholder={t("official_representer_role")} name="official_representer_role" onChange={this.props.handleChange} value={values.official_representer_role} validators={['required']} errorMessages={['this field is required']} width={16} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Input type="text" label={t("company_email")} placeholder={t("company_email")} name="company_email" onChange={this.props.handleChange} value={values.company_email} validators={['required','isEmail']} errorMessages={['this field is required','Should be email']} width={16} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Input type="text" label={t("bank_account")} placeholder={t("bank_account")} name="bank_account" onChange={this.props.handleChange} value={values.bank_account} validators={['required']} errorMessages={['this field is required']} width={16} />
                        </Grid.Column>
                    </Grid.Row>	
                </Grid>
            </Segment>
            ) : ("")}
            


            <Segment>
                <h3>{t("invoice_address")}</h3>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <CountryDropdown
                                type="dropdown"
                                label={t("country")}
                                name='country'
                                placeholder={t("country")}
                                validators={['required']} 
                                errorMessages={['this field is required']}
                                value={values.country}
                                onChange={this.props.handleChange}
                                width={16}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <RegionDropdown
                                type="dropdown"
                                label={t("region")}
                                name='region'
                                placeholder={t("region")}
                                validators={['required']} 
                                errorMessages={['this field is required']}
                                value={values.region}
                                onChange={this.props.handleChange}
                                country_code={values.country}
                                width={16}
                            />		
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Input type="text" label={t("postalCode")} placeholder={t("postalCode")} name="postalCode" onChange={this.props.handleChange} value={values.postalCode} validators={['required']} errorMessages={['this field is required']} width={16}  />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Input type="text" label={t("city")} placeholder={t("city")} name="city" onChange={this.props.handleChange} value={values.city} validators={['required']} errorMessages={['this field is required']} width={16}  />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Input type="text" label={t("street")} placeholder={t("street")} name="street" onChange={this.props.handleChange} value={values.street} validators={['required']} errorMessages={['this field is required']} width={16} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Form>
        )
    }
}

export default withTranslation()(InvoiceDataForm);