import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../../components/Session';

import SignInPage from '../../components/SignIn';
import PasswordForgetPage from '../../components/PasswordForget';
import { RequestInvitation, RequestRegistration } from '../../components/RequestRegistration';
import PackageList from '../../components/Packages/PackageList';

import * as ROUTES from '../../constants/routes';
import { Container } from 'semantic-ui-react'; 
import Navigation from '../../components/Navigation';

class NoAuthPage extends Component {
    render() {
        return (
			<div>
				<Navigation />
				<Container>
					<Switch>
						<Route path={ROUTES.SIGN_IN} component={SignInPage} />
						<Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
						
						<Route path={ROUTES.REGISTER_BY_INVITE} component={RequestInvitation} />
						<Route path={ROUTES.REGISTER_BY_REQUEST} component={RequestRegistration} />

						<Route path={ROUTES.PACKAGES} component={PackageList} />
					</Switch>
				</Container>
			</div>
            
       )
    }
}

export default NoAuthPage;