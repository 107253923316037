import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Header,
  Image,
  List,
  Segment,
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { ExternalLink } from 'react-external-link';

const REPORT_BUG_LINK = "https://tab.euvision.hu/bug-report/";
const FORUM_LINK = "https://tab.euvision.hu/forum/";


const Footer = () => {
    const { t } = useTranslation();

    return  <Segment>
        <Container fluid textAlign='center'>
        <Grid divided stackable>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={3}>
                <PTELogo />
            </Grid.Column>
            <Grid.Column width={3}>
                <Header as='h4' content={t("about")} />
                <List link>
                    <List.Item as='a' href='#'>{t("contact_us")}</List.Item>
                    <List.Item as='a' href='#'>{t("terms_and_conditions")}</List.Item>
                    <List.Item as='a' href='#'>{t("privacy_policy")}</List.Item>
                </List>
            </Grid.Column>
            <Grid.Column width={3}>
                <Header as='h4' content={t("links")} />
                <List link>
                    <List.Item as='a' target="_blank" rel="noopener noreferrer" href={REPORT_BUG_LINK}>{t("report_problem")}</List.Item>
                    <List.Item as='a' target="_blank" rel="noopener noreferrer" href={FORUM_LINK}>{t("forum")}</List.Item>
                </List>
            </Grid.Column>
            <Grid.Column width={3}>
                <SimplePayLogo />
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
        </Grid>
        </Container>
    </Segment>
};

const SimplePayLogo = ({size="tiny"}) => {
    const { i18n } = useTranslation();
    const simplePayPaymentInformationUrl = i18n.language === "hu" ? "http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" : "http://simplepartner.hu/PaymentService/Payment_information.pdf";
    return <Image as={ExternalLink} href={simplePayPaymentInformationUrl} src="/simplepay_bankcard_logos_top_02.png" size={size}/>;
}

const PTELogo = () => {
    const pteUrl = "https://aok.pte.hu/en";
    return <Image as={ExternalLink} href={pteUrl} src="/1200px-Logo_univpecs.svg.png" size='tiny'/>;
}

export {SimplePayLogo, Footer, PTELogo}
export default Footer;

