import React, { Component } from 'react';

import { Form, Input, Button } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import { withTranslation  } from 'react-i18next';
 

const INITIAL_STATE = {
    firstName: '',
    middleName: '',
    lastName: '',
    medicalCardId: '',
    birthDate: '',
    lastExaminationDate : ''
};


class PatientSearchForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
   
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onClick = event => {
        const searchFields = this.state;
        console.log(searchFields);
        this.props.onSearch(searchFields);
    };

    onClearForm = () => {
        const emptyValues = { ...this.state, ...INITIAL_STATE};
        this.setState( emptyValues );
        this.props.onSearch(emptyValues);
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onDateChange = (event,data) =>{
        this.setState({ [data.name]: data.value });
     };

    render() {
        const { firstName, middleName, lastName, medicalCardId,birthDate,lastExaminationDate } = this.state;
        const isInvalid = firstName === '' && middleName === '' && lastName === '' && medicalCardId === '' && birthDate === '' && lastExaminationDate === '';
        const { t } = this.props;

        return (
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>{t("firstName")}</label>
                        <Input fluid placeholder={t("firstName")} name="firstName" onChange={this.onChange} value={firstName} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("middleName")}</label>
                        <Input fluid placeholder={t("middleName")} name="middleName" onChange={this.onChange} value={middleName} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("lastName")}</label>
                        <Input fluid placeholder={t("lastName")} name="lastName" onChange={this.onChange} value={lastName} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("medicalId")}</label>
                        <Input fluid placeholder={t("medicalId")} name="medicalCardId" onChange={this.onChange} value={medicalCardId} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("dateOfBirth")}</label>
                        <SemanticDatepicker fluid  name="birthDate" onChange={this.onDateChange} value={birthDate} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("lastExaminationDate")}</label>
                        {/* <Input fluid placeholder='Last Examination date' name="lastExaminationDate" /> */}
                        <SemanticDatepicker fluid  name="lastExaminationDate" onChange={this.onDateChange} value={lastExaminationDate}  />
                    </Form.Field>                
                </Form.Group>
                <Button color='teal' /* disabled={isInvalid} */ type="submit" onClick={this.onClick}>{t("search")}</Button>
                <Button color='red' type="button" onClick={this.onClearForm}>{t("clear_filter")}</Button>

            </Form>
        );
  }
}

export default withTranslation()(PatientSearchForm);
