import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import Footer from '../Footer';
// import LandingPage from '../Landing';


// import HomePage from '../Home';
// import AccountPage from '../Account';



import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { withTranslation  } from 'react-i18next';
import { SemanticToastContainer } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import NoAuthPage from '../../pages/NoAuthApp';
import WorkgroupPage from '../../pages/MainApp';

const App = ({t}) => (
  <Router>
    <div>
      {/* <Navigation /> */}
      <SemanticToastContainer position="top-center" animation="slide up" />
      {/* <Container> */}
        {/* <Route exact path={ROUTES.LANDING} component={LandingPage} /> */}
        {/* <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        
        <Route path={ROUTES.REGISTER_BY_INVITE} component={RequestInvitation} />
        <Route path={ROUTES.REGISTER_BY_REQUEST} component={RequestRegistration} />

        
        <Route path={ROUTES.PACKAGES} component={PackageList} /> */}
        {
           ROUTES.NOAUTH_PAGES.map( page => {
              if( page === ROUTES.LANDING)
                return <Route exact path={ROUTES.LANDING} component={NoAuthPage} />
              else
                return <Route path={page} component={NoAuthPage} />
           })
        }

        {
           ROUTES.WORKGROUP_PAGES.map( page => {
            return <Route path={page} component={WorkgroupPage} />
           })
        }


        {/* <Route path={ROUTES.PASSWORD_CHANGE} component={ChangePasswordForm} />
        <Route path={ROUTES.PURCHASES} component={PurchasesList} />
        <Route path={ROUTES.GROUPS} component={GroupList} />
        <Route path={ROUTES.GROUP_INVITE_MEMBER} component={InviteMemberForm} />
        <Route path={ROUTES.GROUP_DETAIL} component={GroupDetail} />
        <Route path={ROUTES.DOWNLOAD} component={DownloadList} />
        <Route path={ROUTES.PROBABILITY_CALCULATOR} component={ProbabilityCalculator} />
        <Route path={ROUTES.PATIENTS} component={PatientPage} />
        <Route path={ROUTES.PACKAGES} component={PackageList} />
        <Route path={ROUTES.ACCOUNT} component={AccountForm} />
        
        <Route path={ROUTES.ADMIN} component={AdminPage} />
         */}
        {/* <Route path={ROUTES.PATIENT_DETAIL} component={PatientPage} /> */}
      {/* </Container> */}
      <Footer />
    </div>
  </Router>
);
export default withTranslation()(withAuthentication(App));
