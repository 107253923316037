import React from 'react';
import {Dropdown} from 'semantic-ui-react-form-validator';
import {  CountryRegionData } from 'react-country-region-selector';


const CountryDropdown = ( props ) => {
    let options =  CountryRegionData.map(x =>{ return  {key: x[1], text: x[0], value: x[1]}; });
    return <Dropdown
        type="dropdown"
        search
        selection
        options={options}
        {...props}
    />
};

const RegionDropdown = ( props ) => {
    let options =[];
    if(props.country_code)
    {
        options =  CountryRegionData.filter(x => x[1] == props.country_code)[0][2].split("|").map(x =>{
            let region = x.split("~");
            return  {key: region[1], text: region[0], value: region[1]}; 
        });
    }
    return <Dropdown
        type="dropdown"
        search
        selection
        options={options}
        {...props}
    />
};

const GetCountryName = ( countryCode ) =>{
    return CountryRegionData.filter(x => x[1] == countryCode)[0][0];
};
const GetRegionName = ( countryCode, regionCode ) =>{
    return CountryRegionData.filter(x => x[1] == countryCode)[0][2].split("|").map(y => {return y.split("~")}).filter(z=> z[1] == regionCode)[0];
};


export {CountryDropdown, RegionDropdown, GetCountryName, GetRegionName};
export default CountryDropdown;