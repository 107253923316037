import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { withAuthorization } from '../../Session';

import { withTranslation  } from 'react-i18next';
import { Header, Loader, Table, Button } from 'semantic-ui-react';

class GroupList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      groups: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    //get items
    this.getItems();
  }

  componentWillUnmount() {
    //this.unsubscribe();
  }

  getItems( ) {
    this.setState({ loading: true });

    console.log(this.props.authUser.groups);
    let groups = this.props.authUser.groups.filter(g => g.isOwner === true);
    console.log(groups);

    this.setState({
      groups,
      loading: false
    });
  }

  render() {
    const { groups, loading } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Header as="h1" color="teal" dividing>{t("userGroups")}</Header>

        {loading ? (
          <Loader active inline />
        ) : (
          <Table singleLine striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("groupName")}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {groups.map((group, i) => (
                <Table.Row key={group.id}>
                  <Table.Cell>{group.name}</Table.Cell>
                  <Table.Cell>
                    <Button 
                      primary 
                      as={Link}
                      to={{
                        pathname: `/group/${group.id}`,
                        state: { group },
                      }}
                      >{t("edit")}</Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='3' >
                  </Table.HeaderCell>
                </Table.Row>                
            </Table.Footer>
          </Table>
        )}
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withTranslation(),
)(GroupList);
