import React from 'react';
import {Table, Button } from 'semantic-ui-react';

import { withTranslation  } from 'react-i18next';
import i18n from "../../i18n";

const timeStampToDate = (timeStamp) => {
  return new Date( timeStamp.seconds*1000);
};


const ExaminationList = (props) => {
    const {t, examinations} = props;
    
    return (
        <Table singleLine striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("examinationType")}</Table.HeaderCell>
              <Table.HeaderCell>{t("date")}</Table.HeaderCell>
              <Table.HeaderCell>{t("summary")}</Table.HeaderCell>
              <Table.HeaderCell>{t("examinerComment")}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {examinations.map((examination, i) => (
              <Table.Row key={examination.id}>
                <Table.Cell>{examination.type}</Table.Cell>
                <Table.Cell>{(typeof examination.date === "undefined") ? "" : (timeStampToDate(examination.date)).toLocaleDateString(i18n.language) }</Table.Cell>
                <Table.Cell>{<p dangerouslySetInnerHTML={{ __html: examination.summary.replace(';','<br/>') }}></p>}</Table.Cell>
                <Table.Cell>{examination.examinerComment}</Table.Cell>
                <Table.Cell>
                  {/* <Button primary disabled >{t("details")}</Button> */}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5' >
                </Table.HeaderCell>
              </Table.Row>           
          </Table.Footer>
        </Table>
      );
};
export default withTranslation()(ExaminationList);