import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';

import { Button, Header, Segment } from 'semantic-ui-react'
import {Form, Input } from 'semantic-ui-react-form-validator';

import { withFirebase } from '../../Firebase';
import { withTranslation  } from 'react-i18next';
import i18n from "../../../i18n";
import * as alert from "../../Elements/AlertMsg";
import * as ROUTES from '../../../constants/routes';
 

const INITIAL_STATE = {
    name: '',
    email: '',
    loading: false,
    error: null,
};


class InviteMemberForm extends Component {
    constructor(props) {
        super(props);

        this.invitedUserRef = this.props.firebase.invitedUsers();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
        this.state = { ...INITIAL_STATE, ...props.location.state };
    }

    componentDidMount() {
        const { name,email,groupId } = this.state;
        let b = this.authUser.groups.filter(x => x.id === groupId)[0].name;
        console.log(b);
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onSubmit = event => {
        const { t, history } = this.props;

        this.setState({ loading: true });
        let inviteData = this.prepareInviteData();
        let inviteUserMethod = this.props.firebase.functions.httpsCallable('user-inviteUserToGroup');
        inviteUserMethod(inviteData).then(result => {
            // Read result of the Cloud Function.
            console.log(result);
            const redirectCallback = () => { history.push(ROUTES.GROUP_DETAIL.replace(":id", inviteData.group.id)); }
            if( result.data.code === 200)
            {
                alert.successAlert(t("title_success"),t("msg_user_succesfully_invited_already_exist"),redirectCallback);
            }
            else if( result.data.code === 201)
            {
                alert.successAlert(t("title_success"),t("msg_user_succesfully_invited_email"), redirectCallback);
            }
            else
            {
                alert.errorAlert(t("title_error"),t("msg_user_error_invited"));
            }
            this.setState({ loading: false });
        }).catch(error => {
            // Getting the Error details.
            console.log(error);
            alert.errorAlert(t("title_error"),t("msg_user_error_invited"));
            this.setState({ loading: false });
        });
    };

    prepareInviteData = () => {
        const { name,email,groupId } = this.state;
        let inviteData = {};
        inviteData['name'] = name;
        inviteData['email'] = email;
        inviteData['group'] = {
            id : groupId,
            name : this.authUser.groups.filter(x => x.id === groupId)[0].name 
        }
        inviteData['invitedBy'] = {
            id : this.authUser.uid,
            name : this.authUser.fullName
        };
        inviteData['date'] =  new Date();
        inviteData['app_origin_url'] = window.location.origin;
        inviteData['lang'] = i18n.language;

        return inviteData;
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { t } = this.props;
        const { name,email, loading} = this.state;

        //const isInvalid = name === '' || email === '' ;

        return (
            <div>
                <Header as="h1" color="teal" dividing>{t("inviteMember")}</Header>
                <Segment loading={loading}>
                    <Form onSubmit={this.onSubmit}>
                        <Input type="text" label={t("name")} placeholder={t("name")} name="name" onChange={this.handleChange} value={name} validators={['required']} errorMessages={['this field is required']} width={4} /> 
                        <Input type="text" label={t("email")} placeholder={t("email")} name="email" onChange={this.handleChange} value={email} validators={['required','isEmail']} errorMessages={['this field is required','Should be email']} width={4} />
                        <Button color='teal' type="submit">{t("inviteMember")}</Button>            
                    </Form>
                </Segment>
            </div>
        );
  }
}

export default withTranslation()(withFirebase(InviteMemberForm));
