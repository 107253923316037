import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { withAuthorization } from '../Session';

import { withTranslation  } from 'react-i18next';
import { Header, Loader, Table, Button } from 'semantic-ui-react';
import * as ROUTES from '../../constants/routes';
import i18n from "../../i18n";

class PurchasesList extends Component {

  constructor(props) {
    super(props);

    this.purchaseRef = this.props.firebase.db.collection('Purchase').where("user.id","==", this.props.authUser.uid);
    this.patientQuery = this.purchaseRef;
    this.state = {
      loading: false,
      purchases: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    //get items
    this.getItems();
  }

  componentWillUnmount() {
    //this.unsubscribe();
  }

  getItems( ) {
    this.setState({ loading: true });

    //get results
    this.purchaseRef
    .onSnapshot(snapshot => {
      let purchases = [];
      snapshot.forEach(doc =>{
        let purchase = { ...doc.data(), uid: doc.id };        
        purchases.push(purchase);
      }
      );

      console.log(purchases);

      this.setState({
        purchases,
        loading: false
      });
    });

  }

  render() {
    const { purchases, loading } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Header as="h1" color="teal" dividing>{t("purchases")}</Header>
        <Button primary as={Link} to={{ pathname: `/packages`}}>{t("buyPackage")}</Button>
        {loading ? (
          <Loader active inline />
        ) : (
          <Table singleLine striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("package")}</Table.HeaderCell>
                <Table.HeaderCell>{t("date")}</Table.HeaderCell>
                <Table.HeaderCell>{t("expirationDate")}</Table.HeaderCell>
                <Table.HeaderCell>{t("nrOFExaminations")}</Table.HeaderCell>
                <Table.HeaderCell>{t("isActive")}</Table.HeaderCell>
                <Table.HeaderCell>{t("paymentType")}</Table.HeaderCell>
                <Table.HeaderCell>{t("price")}</Table.HeaderCell>
                <Table.HeaderCell>{t("groupName")}</Table.HeaderCell>
                <Table.HeaderCell>{t("invoiceUrl")}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {purchases.map((purchase, i) => (
                <Table.Row key={purchase.uid}>
                  <Table.Cell>{purchase.package.name}</Table.Cell>
                  <Table.Cell>{(typeof purchase.date === "undefined") ? "" : purchase.date.toDate().toLocaleDateString(i18n.language) }</Table.Cell>
                  <Table.Cell>{(typeof purchase.expiration === "undefined") ? "" : purchase.expiration.toDate().toLocaleDateString(i18n.language) }</Table.Cell>
                  <Table.Cell>{purchase.package.totalExaminationsLimit - purchase.examCountTot} / {purchase.package.totalExaminationsLimit}</Table.Cell>
                  <Table.Cell>{purchase.isActive.toString() }</Table.Cell>
                  <Table.Cell>{purchase.paymentType }</Table.Cell>
                  <Table.Cell>{purchase.totalSalePrice}</Table.Cell>
                  <Table.Cell><Link to={ROUTES.GROUP_DETAIL.replace(":id",purchase.group.id)} >{purchase.group.name}</Link></Table.Cell>
                  <Table.Cell><a href={purchase.invoiceUrl} target="_blank" rel="noopener noreferrer">{t("invoiceUrl")}</a></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='9' >
                  </Table.HeaderCell>
                </Table.Row>
                
            </Table.Footer>
          </Table>
        )}
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withTranslation(),
)(PurchasesList);
