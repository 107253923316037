import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import withGroup from '../Session/withGroup';

import { Form, Input, Button, Message, Transition} from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { withTranslation  } from 'react-i18next';
import * as SETTINGS from '../../constants/settings';

import readXlsxFile from 'read-excel-file'



class PatientImport extends Component {
    constructor(props) { 
        super(props);

        this.patientRef = this.props.firebase.patients();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));

        //JSON.parse(localStorage.getItem('authUser'))
        this.state = {
            fileName: '',
            templateURL: '',
            file: null,
            notification: null
        };
    }

    componentDidMount() {
       /*  this.props.firebase.storage
      		.ref('Patient_import.xlsx')
      		.getDownloadURL()
      		.then(url => {
				  this.setState({ templateURL: url })
            }); */
        this.props.firebase.storage
			.ref("template")
      		.child('Patient_import_template.xlsx')
      		.getDownloadURL()
      		.then(url => {
				  this.setState({ templateURL: url })
            });
        
        fetch(SETTINGS.IPAPI_URL)
            .then(response => response.json())
            .then(data => {
                const location = {
                    country : {
                        code: data.country,
                        name: data.country_name
                    },
                    region: {
                        code: data.region_code,
                        name: data.region
                    },
                    city: data.city,
                    isEU: data.in_eu,
                    continent: data.continent_code,
                    timezone: data.timezone,
                    postalCode: data.postal,
                    ip: data.ip,
                    gps: this.props.firebase.getGeoPoint(data.latitude, data.longitude)
                }
                this.setState({
                    location: location
                });
            });
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onHandleImport = () => {
        const { file } = this.state;
        const { t } = this.props;
        this.setState({
            fileName: ''
        });

        const schema = {
            'FirstName': {
                prop: 'FirstName',
                type: String,
                required: true
            },
            'MiddleName': {
                prop: 'MiddleName',
                type: String
            },
            'LastName': {
                prop: 'LastName',
                type: String
            },
            'BirthDate': {
                prop: 'BirthDate',
                //type: Date,
                required: true,
                parse(value){
                    let dateValue = new Date(value);
                    if(isNaN(dateValue.getTime()))
                    {
                        throw new Error('invalid date');
                    }
                    return dateValue;
                }
            },
            'MedicalId': {
                prop: 'MedicalId',
                type: String,
                required: true
              },
            'MotherName': {
                prop: 'MotherName',
                type: String,
                required: true
            },

          }




        readXlsxFile(file,{ schema })
            .then(({ rows }) => {
                // `rows` is an array of rows
                // each row being an array of cells.
                //console.log(errors);
                this.validateData(rows).then((result) =>{
                    console.log(result);
                    const {data,errors,nrOfExistedPatients} = result;
                    if( errors.length > 0)
                    {
                        // parse error
                        let errorList = [];
                        for(let error of errors)
                        {
                            let val = t('line') + ' ' + error.row  + ' ' + t('field') + ' ' + error.column + ': ' + error.error; 
                            errorList.push(val);
                        }
                        this.setState({
                            notification: { ...this.state.notification, error: errorList}
                        });
                    }
                    if( nrOfExistedPatients > 0)
                    {
                        let warningList = [];
                        warningList.push(t( "msg_patients_already_exist_with_medicalId").replace("[nrPatients]",nrOfExistedPatients));
                        console.log(this.state.notification);
                        const newNotification = { ...this.state.notification, warning: warningList};
                        console.log(newNotification);
                        this.setState({
                            notification: newNotification
                        });
                    }

                    if( data.length > 0)
                    {
                        const nrValidData = data.length;
                        // save data
                        this.importData(data).then(() =>{
                            let successList = [];
                            successList.push(t("msg_patient_import_successfully").replace("[nrPatients]",nrValidData));
                            this.setState({
                                notification: { ...this.state.notification, success: successList}
                            });
                        });
                    }
                }).catch(e =>{
                    console.log(e);
                });
            })
            .catch(( error) => {
                console.log('error');
            });

    }
        
    validateData = async(data) => {
        let errorsAll = [];
        let validData = [];
        let nrOfExistedPatients = 0;
        let i = 0;
        for( let row of data)
        {
            i++;
            let {errors, existedPatient }  = await this.validateRow(row, i);
            if( errors.length > 0)
            {
                errorsAll = errorsAll.concat(errors);
            }
            else if( existedPatient === true)
            {
                nrOfExistedPatients ++;
            }
            else
            {
                validData.push(row);
            }
        }


        return {
            data: validData,
            errors: errorsAll,
            nrOfExistedPatients: nrOfExistedPatients,
        };
    }

    validateRow = async (row, rowIndex) =>{
        const { t } = this.props;
        let errors = [];
        let patientExist = false;
        let groupId = this.props.workgroup.id;
        if( !row.hasOwnProperty("FirstName") || row["FirstName"] === '' || row["FirstName"].length === 0)
        {
            console.log("firstName");
            errors.push({ 
                column: "FirstName",
                row: rowIndex,
                error: t("msg_field_required")
            });
        }
        if( !row.hasOwnProperty("MotherName") || row["MotherName"] === '' || row["MotherName"].length === 0)
        {
            errors.push({ 
                column: "MotherName",
                row: rowIndex,
                error: t("msg_field_required")
            });
        }

        if( (row.hasOwnProperty("MiddleName") && row["MiddleName"] !== '')  && (!row.hasOwnProperty("LastName") || row["LastName"] === '' ))
        {
            errors.push({ 
                column: "LastName",
                row: rowIndex,
                error: t("msg_field_required")
            });
        }

        let dateValue = new Date(row["BirthDate"]);
        if(isNaN(dateValue.getTime()))
        {
            errors.push({ 
                column: "BirthDate",
                row: rowIndex,
                error: t("msg_invalid_date")
            });
        }
        /*if( row["BirthDate"] === '')
        {
            errors.push({ 
                column: "BirthDate",
                row: rowIndex,
                error: "The field is required"
            });
        }*/

        if( row["MedicalId"] === '')
        {
            errors.push({ 
                column: "MedicalId",
                row: rowIndex,
                error: t("msg_field_required")
            });
        }
        

        if( errors.length === 0)
        {
            patientExist =  await this.verifyPatient(groupId,row["MedicalId"].toString());
            if( patientExist)
            {
                /*errors.push({ 
                    column: "MedicalId",
                    row: rowIndex,
                    error: "Patient already exist with medicalId"
                });*/
            }
        }
        return { 
            errors : errors, 
            existedPatient: patientExist 
        };
    }


    onFileChange = event =>{
        this.setState({ 
            fileName: event.target.value,
            file: event.target.files[0]
        });

        // filename
        //console.log('filename ' + event.target.value);
        //file 
        //console.log('file ' + event.target.files[0]);
    };

    importData = async( items ) => {
        var batchSize = 500;
        while (items.length) {
            var batchItems = items.splice(0, batchSize);
            try {
                const batch = this.props.firebase.db.batch();
                for( let item of batchItems)
                {
                    let patientData =  this.preparePatienData(item);
                    let docRef = this.patientRef.doc();
                    //console.log(docRef);
                    batch.set(docRef, patientData);
                }
                batch.commit();
            } catch (error) {
                console.log(error);
            }
        }
    };

    verifyPatient = async( groupId, medicalId) =>{
        let patients = await this.patientRef
                .where("group.id","==",groupId)
                .where("medicalCardNumber","==",medicalId)
                .limit(1)
                .get();
        return patients.size > 0;
    }

    preparePatienData = (item) => {
        const { i18n } = this.props;

        let  patientData = {};
        patientData['medicalCardNumber'] = item['MedicalId'].toString();
        
        patientData['birthday'] = new Date(item['BirthDate']);
        patientData['birthdayStr'] = patientData['birthday'].toISOString().split("T")[0];
        
        patientData['motherName'] = item['MotherName'];
        patientData['date'] =  new Date();
        patientData['isImported'] = true;

        if(item.hasOwnProperty("LastName") && item.LastName !== '')
        {
            patientData['name'] = {
                First: item['FirstName'],
                Last: item['LastName'],
                Middle: (item.hasOwnProperty("MiddleName") && item['MiddleName'].length > 0) ? item['MiddleName'].split(' ') : []
            };
            if( i18n.language === 'hu' )
            {
                patientData['fullName'] = item['LastName'] + ' ' + item['FirstName'] + ' ' + ((item.hasOwnProperty("MiddleName") && item['MiddleName'] !== '') ? item['MiddleName'] : '');
            }
            else
            {
                patientData['fullName'] = item['FirstName'] +  ' ' + ((item.hasOwnProperty("MiddleName") && item['MiddleName'] !== '') ? item['MiddleName'] + ' ' : '') +  item['LastName'];
            }
        }
        else
        {
            const nameFormat = i18n.language === 'hu' ? "LFM" : "FML";
            patientData['name'] = this.getNames(item['FirstName'],nameFormat);
            patientData['fullName'] = item['FirstName'];
        }
        
        
        patientData['lc_motherName'] = patientData['motherName'].toLowerCase();
        patientData['lc_name'] = {
            First: patientData['name']['First'].toLowerCase(),
            Last: patientData['name']['Last'].toLowerCase(),
            Middle: patientData['name']['Middle'].length > 0 ? patientData['name']['Middle'].map(x => {return x.toLowerCase();}) : []
        };

        patientData['user'] = {
            id : this.authUser.uid,
            name : this.authUser.fullName,
            ref : this.props.firebase.db.collection(`Users`).doc(this.authUser.uid)
        }

        patientData['group'] = this.props.workgroup;

        patientData['examinations'] = [];
        patientData['lastExaminationDate'] = null;
        patientData['isActive'] = true;

        patientData['registered_location'] = this.state.location;

        
// registered.country
// registered.region
// registered.city
// registered.address
// registered.gps.latitude
// registered.gps.longitude
// registered.ip

        return patientData;
    };

    getNames = (name, format) => {
        let splitStringArr = name.split(' ');
        let names = {};
        names['First'] = '';
        names['Last'] = '';
        names['Middle'] = [];

        
        if( format === 'LFM') // last first middle
        {
            names['First'] = splitStringArr[1];
            names['Last'] = splitStringArr[0];
            names['Middle'] = splitStringArr.slice(2,splitStringArr.length);
        }
        else //first middle last
        {
            names['First'] = splitStringArr[0];
            names['Last'] = splitStringArr[splitStringArr.length-1];
            names['Middle'] = splitStringArr.slice(1,splitStringArr.length-1);
        }
        return names;
    };

    showMessage = (type, notificationList) => {
        const { t } = this.props;
        const colorProps = { [type] : true};
        return <Transition key={type} onHide={() => this.clearNotfication(type)} ><Message onDismiss={() => this.clearNotfication(type)} header={t(type)} list={notificationList} {...colorProps} /></Transition>
    };

    showAllMessages = (notification) => {
        return (
            <React.Fragment>
                {
                    Object.keys(notification).map( type =>{
                        return this.showMessage(type, notification[type])
                    })
                }
            </React.Fragment>
        );
    }

    clearNotfication = (type) => {
        let { notification } = this.state;
        delete notification[type];
        this.setState({ 
            notification: notification
        });
    };


    render() {
        const { fileName, notification, templateURL } = this.state;
        const isFileSelected = fileName === '';
        const { t } = this.props;
        return (
            
            <Form>
                {notification && (
                    this.showAllMessages(notification)
                )}
                <Form.Group inline>
                    <Form.Field>
                        <label>{t("Please_select_the_file")}</label>
                        <Input placeholder={t("fileName")} type='file' name="fileName" onChange={this.onFileChange} value={fileName} />
                    </Form.Field>
                    <Form.Field>
                        <Button color='teal' disabled={isFileSelected} type="submit" onClick={this.onHandleImport}>{t("Import")}</Button>
                    </Form.Field>
                    <Form.Field>
                        <a href={templateURL} >{t("import_template")}</a>    
                    </Form.Field>
                </Form.Group>
            </Form>
        );
  }
}

const condition = authUser => !!authUser;
export default compose(
    withGroup(condition),
    withTranslation(),
  )(PatientImport);

//export default withTranslation()(withFirebase(PatientImport));
