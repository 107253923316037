import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { withAuthorization } from '../../Session';

import { withTranslation  } from 'react-i18next';
import { Header, Loader, Table, Button, Confirm, Popup, Segment, Tab, Dropdown } from 'semantic-ui-react';
import * as alert from "../../Elements/AlertMsg";
import GroupPurchases from "./GroupPurchases";

class GroupDetail extends Component {

	constructor(props) {
		super(props);
		this.groupsRef = this.props.firebase.db.collection('UserGroups');
		this.authUser = this.props.authUser;

		this.state = {
			openDeleteConfirm: false,
			loading: false,
			activePurchasesOptions: [],
			groups: []
		};
	}

	componentDidMount() {
		const { i18n } = this.props;
		this.setState({ loading: true });

		//get items
		this.getItems();
	}

	componentWillUnmount() {
		//this.unsubscribe();
	}

	getItems( ) {
		this.setState({ loading: true });

		this.groupsRef.doc(this.props.match.params.id).get()
			.then(snapshot => {
				let group = snapshot.data();

				console.log(group.users);

				this.setState({
					groupT : group,
					loading: false,
				});
			});
		}

	showConfirmDeletUser(userData){
		this.setState({ 
			openDeleteConfirm: true,
			deletedUser: userData
		});
	}
  
	handleConfirmDeleteUser() {
		const { t, history } = this.props;
		const { deletedUser } = this.state;
		console.log(deletedUser);

		let data = {};
		data['user'] = deletedUser;
		data['groupId'] = this.props.match.params.id;

		this.setState({ loading: true });
		let deleteUserMethod = this.props.firebase.functions.httpsCallable('user-deleteUserFromGroup');
		deleteUserMethod(data).then(result => {
			// Read result of the Cloud Function.
			if( result.data.code === 200)
			{
				alert.successAlert(t("title_success"),t("msg_user_succesfully_deleted_from_group"));
			}
			else
			{
				alert.errorAlert(t("title_error"),t("msg_user_error_deleted_from_group"));
			}
			this.getItems();
			this.setState({ loading: false });
		}).catch(error => {
			// Getting the Error details.
			console.log(error);
			this.setState({ loading: false });
		});

		
		this.setState({ 
			openDeleteConfirm: false,
			deletedUser: null 
		});
	}

  	handleCancelDeleteUser = () => this.setState({ openDeleteConfirm: false, deletedUser: null });

  	renderUserTab( ) {
		const { t } = this.props;
		const { groupT, loading } = this.state;
		let  users = [];
		if( groupT )
		{
			users = groupT.users;
		}


		return <Table singleLine striped>
			<Table.Header>
					<Table.Row>
						<Table.HeaderCell>{t("userName")}</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
			</Table.Header>
			<Table.Body>
				{users.map((user, i) => (
					<Table.Row>
						<Table.Cell>{user.name}</Table.Cell>
						<Table.Cell>
						{
							<Popup
							trigger={
								<div style={{ display: "inline-block" }}>
								<Button primary disabled={user.isOwner} onClick={()=>this.showConfirmDeletUser(user)} >{t("delete")}</Button>
								</div>
							}
							content={ user.isOwner ? t("msg_cannot_delete_user_from_group_isOwner"): t("msg_delete_user_from_group") }
							/>
						}
						
						</Table.Cell>
					</Table.Row>
					))}
			</Table.Body>
			<Table.Footer>
				<Table.Row>
					<Table.HeaderCell colSpan='3' ></Table.HeaderCell>
				</Table.Row>                
			</Table.Footer>
		</Table>;
	}

	renderSetActivePurchase(){
		const { groupT } = this.state;
		return <GroupPurchases groupId={this.props.match.params.id} activePurchase={groupT.activePurchase} onActivePurchaseChange={this.handleActivePurchaseChange} />;
	}

	handleActivePurchaseChange = (newActivePurchase ) => {
		const newFields = { ...this.state.groupT,  activePurchase: newActivePurchase };
        this.setState({ groupT: newFields });
	}

  	render() {
		const { groupT, loading } = this.state;
		const { t } = this.props;
		let  users = [];
		let groupName = '';
		if( groupT )
		{
			groupName = groupT.name;
		}

		const panes = [
			{
				menuItem: t("group_members"),
				render: () => <Tab.Pane attached={false}>{this.renderUserTab()}</Tab.Pane>,
			},
			{
				menuItem: t("packages"),
				render: () => <Tab.Pane attached={false}>{this.renderSetActivePurchase()}</Tab.Pane>,
			},
		];





    return (
      <div>
        <Header as="h1" color="teal" dividing>{t("groupName")}: {groupName}</Header>

        <div>
			<Button primary as={Link} to={{ pathname: `/group_invite`, state: { groupId: this.props.match.params.id },}}>{t("inviteMember")}</Button>
			<Button primary as={Link} to={{ pathname: `/packages`, state: { selected_groupId: this.props.match.params.id }}}>{t("buyPackage")}</Button>
        
			<Segment loading={loading}>
				<Tab menu={{ pointing: true }} panes={panes} />
			</Segment>
        </div>
        <Confirm
            open={this.state.openDeleteConfirm}
            header={t("deleteConfirmHeader")}
            cancelButton={t("cancel")}
            confirmButton={t("ok")}
            content={t("deleteConfirmContent")}
            onCancel={this.handleCancelDeleteUser}
            onConfirm={() => this.handleConfirmDeleteUser()}
          />
      </div>
      
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withTranslation(),
)(GroupDetail);
