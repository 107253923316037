import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../../../components/Session';

import { Button, Checkbox, Segment, Tab } from 'semantic-ui-react'
import {Form, Input, TextArea} from 'semantic-ui-react-form-validator';

import * as ADMIN_ROUTES from '../admin_routes';
import RDSParameterList from './RDSParameterList';

import * as notify from "../../../components/Util/notification";
 



class ParameterSetForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parameterSetItem: {},
            selectedParameterSets : [],
            ...props.location.state
        };
    }

    componentDidMount() {
        if(this.props.match.params.id)
        {
            this.setState({
                parameterSetId: this.props.match.params.id
            });
        }


        this.unsubscribe = this.props.firebase
			.parameterSets()
			.onSnapshot(snapshot => {
				let parameterSetOptions = [];

				snapshot.forEach(doc =>
					parameterSetOptions.push({ 
                        key: doc.id,
                        text: doc.data().name,
                        value: doc.id,
                        description: doc.description
                    })
				);

				this.setState({
					parameterSetOptions
				});
			});
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onHandleSubmit = event => {
        const { history } = this.props;
        const { parameterSetItem } = this.state;
        console.log(parameterSetItem);
        const docRef = this.getDocumentRef();
        docRef.set(parameterSetItem,{ merge : true}).then(res => {
            //history.goBack();
            if( !this.props.match.params.id )
            {
                const psId = docRef.id;
                this.setState({
                    parameterSetId: psId
                });
                history.push(ADMIN_ROUTES.PARAMETER_SET_EDIT.replace(":id",psId));
            }

			notify.success("Parameter set successfully saved");

        }).catch(e =>{
            console.log(e);
        });
    };

    getDocumentRef() {
        if( this.props.match.params.id )
        {
            return this.props.firebase.parameterSets().doc(this.props.match.params.id);
        }
        else
        {
            return this.props.firebase.parameterSets().doc();
        }
	}
	
	onChange = event => {
        const newFields = { ...this.state.parameterSetItem, [event.target.name]: event.target.value };
        this.setState({ parameterSetItem: newFields });
    };

    onCheckboxChange = (event, data) =>{
        const newFields = { ...this.state.parameterSetItem, [data.name]: data.checked };
        this.setState({ parameterSetItem: newFields });
    };

    renderForm() {
        const { parameterSetItem } = this.state;
        return <Form onSubmit={this.onHandleSubmit}>
                <Input type="text" label="Name" placeholder="Name" name="name" onChange={this.onChange} value={parameterSetItem.name} validators={['required']} errorMessages={['this field is required']} width={4}/>
				<Input type="text" label="Glass type" placeholder="Glass type" name="glassType" onChange={this.onChange} value={parameterSetItem.glassType} validators={['required']} errorMessages={['this field is required']} width={4}/>


                <TextArea
                    label="Description"
                    name = 'description'
                    validators={['required']}
                    errorMessages={['Cannot Be empty']}
                    value={parameterSetItem.description}
                    onChange={this.onChange}
                />

				<TextArea
                    label="Comment"
                    name = 'comment'
                    validators={['required']}
                    errorMessages={['Cannot Be empty']}
                    value={parameterSetItem.comment}
                    onChange={this.onChange}
                />


                <Segment compact>
                    <Checkbox 
                        label="Active" 
                        name="isActive" 
                        onChange={this.onCheckboxChange} 
                        toggle  
                        checked={parameterSetItem.isActive} 
                    />
                </Segment>

                <Button color='teal' type="submit">Save</Button>            
            </Form>
    }

    renderRDSParmeterTab() {
        const { parameterSetItem, parameterSetId } = this.state;
        return <React.Fragment>
            <RDSParameterList setId={parameterSetId} setName={parameterSetItem.name} />
        </React.Fragment>;
    }

    render() {
        const { parameterSetId } = this.state;
        let key = parameterSetId;
        if(!parameterSetId)
        {
            key = "newParameterSet";
        }
        console.log(key);

        const panes = [
            {
                menuItem: 'Settings',
                render: () => <Tab.Pane attached={false}>{this.renderForm()}</Tab.Pane>,
            },
            {
                menuItem: 'RDS Parameters',
                render: () => <Tab.Pane attached={false}>{this.renderRDSParmeterTab()}</Tab.Pane>,
            },
        ];

        return (
            
			<div>
            <Tab key={key} menu={{ pointing: true }} panes={panes} />
			</div>
        );
  }
}

const condition = authUser => !!authUser;

export default compose(
	withAuthorization(condition),
  )(ParameterSetForm);
