import React, { Component } from 'react';
import { Route,Switch } from 'react-router-dom';

//import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../../components/Session';
import withGroup from '../../components/Session/withGroup';


import {ChangePasswordForm, AccountForm,PurchasesList,GroupList,GroupDetail} from '../../components/Account';
import DownloadList from '../../components/Downloads';
import ProbabilityCalculator from '../../components/ProbabilityCalculator';
import InviteMemberForm from '../../components/Account/GroupManagement/InviteMemberForm';
import PatientPage from '../../components/Patients';
import AdminPage from '../Admin';

import GroupContext from "../../components/Session/groupContext";
import Navigation from '../../components/Navigation';

import * as ROUTES from '../../constants/routes';
import { Container } from 'semantic-ui-react';      



class WorkgroupPage extends Component {

    constructor(props) {
      super(props);
      const group  = this.props.authUser.groups[0];

      this.state = {
        workgroup: {id: group.id, name: group.name},
        changeWorkgroup: this.handleChangeWorkGroup
      };

      this.handleChangeWorkGroup(group.id);
    }

    componentDidMount() {
      this.setState({ workgroup: this.props.authUser.groups[0] });
    }

    handleChangeWorkGroup = (id) => {
      //console.log(id);
      const group  = this.props.authUser.groups.find(x => x.id === id)
      this.setState({
        workgroup: {id: group.id, name: group.name}
      });
      //this.forceUpdate();
    }
    render() {
        return (
           <GroupContext.Provider value={this.state}>
             <Navigation />
            <Container key={this.state.workgroup.id}>
              <Switch>
                <Route path={ROUTES.PASSWORD_CHANGE} component={ChangePasswordForm} />
                <Route path={ROUTES.PURCHASES} component={PurchasesList} />
                <Route path={ROUTES.GROUPS} component={GroupList} />
                <Route path={ROUTES.GROUP_INVITE_MEMBER} component={InviteMemberForm} />
                <Route path={ROUTES.GROUP_DETAIL} component={GroupDetail} />
                <Route path={ROUTES.DOWNLOAD} component={DownloadList} />
                <Route path={ROUTES.PROBABILITY_CALCULATOR} component={ProbabilityCalculator} />
                <Route path={ROUTES.PATIENTS} component={PatientPage} />
                <Route path={ROUTES.ACCOUNT} component={AccountForm} />
                <Route path={ROUTES.ADMIN} component={AdminPage} />
              </Switch>
            </Container>
           </GroupContext.Provider>
       )
    }
}

const condition = authUser => !!authUser;

export default compose(
	withGroup(condition),
  )(WorkgroupPage);