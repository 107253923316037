import React from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Header, Icon, Segment, Modal, Container, Message, Divider, Grid } from 'semantic-ui-react'
import { message } from '../Util/notification';


const alert_message = (type, title, message, callback) => {
    let iconName = "info";
    let alertColor = 'teal';
    let buttonColor = 'teal';
	if( type === "success")
	{
		iconName = "checkmark";
        alertColor = 'positive';
        buttonColor = alertColor;
	}
	else if( type === "error")
	{
		iconName = "remove";
        alertColor = 'negative';
        buttonColor = alertColor;
	}
	else if(type === "info")
	{
		iconName = "info";
        alertColor = 'info';
        buttonColor = 'blue';
	}
	else if( type === "warning")
	{
		iconName = "warning sign";
        alertColor = 'warning';
        buttonColor = 'orange';
	}

	confirmAlert({
		customUI: ({ onClose }) => {
		  return (
			<Modal open={true} dimmer="blurring"  size="tiny" closeOnEscape={false} closeOnDimmerClick={false} >
                <Message color={alertColor} >
                        <Header as="h2">
                            <Icon name={iconName} />{title}
                        </Header>
                        <Divider />
                        <p dangerouslySetInnerHTML={{ __html: message }}></p>
                        <Divider />
                        <Grid>
                            <Grid.Column textAlign="center">
                                <Button color={buttonColor} onClick={() => {
                                    onClose();
                                    if(callback !== undefined)
                                    {
                                        console.log(callback)
                                        callback();
                                    }
                                }}>
                                OK
                                </Button>
                            </Grid.Column>
                        </Grid>
                </Message>
			</Modal>
		  );
		}
	});
}

const successAlert =(title, message, callback) => {
    alert_message("success",title, message,callback);
}

const errorAlert =(title, message, callback) => {
    alert_message("error",title, message,callback);
}

const infoAlert =(title, message, callback) => {
    alert_message("info",title, message,callback);
}

const warningAlert =(title, message, callback) => {
    alert_message("warning",title, message,callback);
}

export { successAlert, warningAlert, errorAlert, infoAlert} ;