import React from 'react';
import { Link } from 'react-router-dom';
import * as ADMIN_ROUTES from '../admin_routes';
import { Container, Menu} from 'semantic-ui-react';

const AdminNavigation= () => {
	return <Menu pointing>
	  <Container>
		<Menu.Item name="Packages" as={Link} to={ADMIN_ROUTES.PACKAGE_LIST} />
		<Menu.Item name="Parameter sets" as={Link} to={ADMIN_ROUTES.PARAMETER_SET_LIST} />
		<Menu.Item name="RDS Parameters" as={Link} to={ADMIN_ROUTES.RDS_PARAMETER_LIST} />
	  </Container>
	</Menu>
  };

  export default AdminNavigation;