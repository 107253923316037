import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import {Icon, Item, Header, Loader} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next';


class DownlodList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			appURL: null,
			...props.location.state
		};
	}

	componentDidMount() {
		this.props.firebase.storage
			.ref("app")
      		.child('app-production-release_0_25.apk')
      		.getDownloadURL()
      		.then(url => {
				  this.setState({ appURL: url })
			});

		/*this.props.firebase.db.collection("Downloads").where("type","android-app").orderBy("date","ASC").limit(1).get().then(x => {
			this.setState({ appURL: url })
		})
		.catch(e =>{
			console.log(e);
		})*/
	}

	componentWillUnmount() {
		//this.unsubscribe();
	}


	render() {
		const { loading, appURL} = this.state;
		const { t} = this.props;
	


		return (
			<div>
				<Header as="h1" color="teal" dividing>{t("downloads")}</Header>

				{loading ? (
					<Loader active inline />
				) : (
					<Item.Group divided>
						<Item>
							<Item.Image as='a' href={appURL} size='mini' src='/favicon-32x32.png' />
							<Item.Content verticalAlign='middle'>
								<Item.Header as='a' href={appURL}>
									<Icon name="android"/>
									{t("android_application")}
								</Item.Header>
							</Item.Content>
						</Item>		
					</Item.Group>
				)}
			</div>
		);
	}
}


export default withTranslation()(withFirebase(DownlodList));