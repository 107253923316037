import React from 'react';
import { compose } from 'recompose';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';

import * as ROUTES from '../../constants/routes';
import AccountForm from './AccountForm';
import ChangePasswordForm from './ChangePasswordForm';

const AccountPage = () => (
  <div>
    <h1>Account Page</h1>
    <p>The Account Page is accessible by every signed in user.</p>

    <Switch>
      <Route exact path={ROUTES.ACCOUNT} component={AccountForm} />
      <Route exact path={ROUTES.PASSWORD_CHANGE} component={ChangePasswordForm} />

    </Switch>
  </div>
);

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(AccountPage);
