import React, {useContext} from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import i18n from "../../i18n";
import { withTranslation, useTranslation } from 'react-i18next';
import GroupNavigation from './GroupNavigation';

import { Container, Menu,Dropdown, Image,Header, Popup } from 'semantic-ui-react';
import GroupContext from '../Session/groupContext';
const langugeOptions = [
  { key: 'en', value: 'en', flag: 'gb', text: 'EN' },
  { key: 'es', value: 'es', flag: 'es', text: 'ES' },
  { key: 'hu', value: 'hu', flag: 'hu', text: 'HU' }
]

const handleOnChangeLanguage = (e, data) => {
  i18n.changeLanguage(data.value);
}

const Navigation = ({t}) => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser}) => {
  const { t } = useTranslation();
  return <Menu pointing secondary color="teal">
    <Container>
      <Menu.Header >
        <Popup trigger={<Image as={Link} to="/" src="/euvision_logo_turisz.small.jpg" size='small'/>} content={t("home")} />
          
      </Menu.Header>

      <Menu.Item name={t('patients')} as={Link} to={ROUTES.PATIENTS} />
      <Menu.Item name={t("packages")} content={t("packages")} as={Link} to={ROUTES.PACKAGES} />

		{
			!!authUser.isAdmin ? <Menu.Item name="Admin" as={Link} to={ROUTES.ADMIN} /> : ""
		}
      <Menu.Menu position='right'>
      {/* <GroupNav authUser={authUser} /> */}
      <GroupNavigation authUser={authUser} />
      <Dropdown text={authUser.fullName} className='item'>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={ROUTES.ACCOUNT}>{t("account")}</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.PASSWORD_CHANGE}>{t("changePassword")}</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.PURCHASES}>{t("purchases")}</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.GROUPS}>{t('groupManagement')}</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.DOWNLOAD}>{t('downloads')}</Dropdown.Item>
            <Dropdown.Item as={Link} to={ROUTES.PROBABILITY_CALCULATOR}>{t('probabilityCalculator')}</Dropdown.Item>

            <Dropdown.Divider />
            <SignOutButton />
          </Dropdown.Menu>
        </Dropdown>
        <LanguageNav />
      </Menu.Menu>
    </Container>
    
  </Menu>
};

const NavigationNonAuth = () => {
  const { t, i18n } = useTranslation();
  return <Menu pointing secondary>
    <Container>
      <Menu.Header >
          <Popup trigger={<Image as={Link} to="/" src="/euvision_logo_turisz.small.jpg" size='small'/>} content={t("home")} />
      </Menu.Header>
      {/* <Menu.Item name="home" as={Link} to={ROUTES.LANDING} /> */}
      <Menu.Item name={t("packages")} as={Link} to={ROUTES.PACKAGES} />
      <Menu.Menu position="right">
        <Menu.Item name="signin" as={Link} to={ROUTES.SIGN_IN} />
        <LanguageNav />
      </Menu.Menu>
    </Container>
  </Menu>
};

const LanguageNav = () => {
  console.log(i18n.language);
  return <Dropdown options={langugeOptions} className='item' onChange={handleOnChangeLanguage} value={i18n.language} />
};

const GroupNav = ({authUser}) => {
  const groupContext = useContext(GroupContext) 


  const handleOnChangeGroup = (e, data) => {
    console.log(data);
    authUser.selectedGroup = data.value;
    //localStorage.setItem('authUser', JSON.stringify(authUser));
    //window.location.reload();

/* 	const groupName = data.options.find(x => x.value === data.value).text;
	const group = { id: data.value, name: groupName};
    groupContext.setSelectedGroup(group); */
  }
  
  let groupOptions = [];
  for(let item of authUser.groups)
  {
      groupOptions.push({
        key : item.id,
        value : item.id,
        text: item.name        
      });
  }

	 if( !authUser.selectedGroup)
  {
    authUser.selectedGroup = groupOptions[0].value;
  } 

  /*if( groupContext.selectedGroup === null)
  {
    const group = { id: groupOptions[0].value, name: groupOptions[0].text};
    groupContext.setSelectedGroup(group);
  }*/


  return <Dropdown options={groupOptions} className='item' onChange={handleOnChangeGroup} defaultValue={authUser.selectedGroup}  />
};


export default withTranslation()(Navigation);
