import React from 'react';
import { PACKAGE_ADD } from '../../pages/Admin/admin_routes';

const GroupContext = React.createContext({
	workgroup: null,
	changeWorkgroup: (id) => {}
});


class GroupContextProvider extends React.Component {

	constructor(props) {
		super(props);
		
        this.state = {
			workgroup : null,
			changeWorkgroup: this.handleChangeWorkGroup,
		};
    }

	componentDidMount() {
		if( this.props.workgroup)
		{
			this.setState({ workgroup: this.props.workgroup });
		}
		//this.setState({ workgroup: this.props.authUser.groups[0] });
	  }

	handleChangeWorkGroup = (group) => {
		this.setState({ 
			...this.state,
			workgroup: group
		});
	}

	render() {
		return (
			<GroupContext.Provider value={ this.state }>
				{this.props.children}
			</GroupContext.Provider>
		);
	}
}

export {GroupContextProvider, GroupContext};
export default GroupContext;