import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
//import { Redirect } from 'react-router-dom';
import { Button, Header, Segment, Loader } from 'semantic-ui-react';
import { withFirebase } from '../Firebase';

import {Form, Input } from 'semantic-ui-react-form-validator';
import * as alertModal from "../Elements/AlertMsg";
import * as ROUTES from '../../constants/routes';
import * as utilName from '../Util/names';
import i18n from "../../i18n";


 

const INITIAL_STATE = {
    name: '',
    email: '',
    password: '',
    repeatPassword: '', 
    phone: '',
    error: null,
    invitedUser: null,
    loading: true
};

class RequestInvitation extends Component {
    constructor(props) {
        super(props);

        this.invitedUserRef = this.props.firebase.invitedUsers();
        this.state = { ...INITIAL_STATE, ...props.location.state };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.unsubscribe = this.props.firebase
            .invitedUser(this.props.match.params.id)
            .onSnapshot(snapshot => {
                console.log(snapshot.data());
                this.setState({
                    invitedUser: snapshot.data(),
                    name: snapshot.data().name, 
                    email: snapshot.data().email,
                    password: '',
                    repeatPassword: '', 
                    loading: false,
                });
            });
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onHandleSubmit = event => {
        const { history, t } = this.props;
        
        let data = this.prepareData();
        
        var createUserMethod = this.props.firebase.functions.httpsCallable('user-createInvitedUserAccount');
        createUserMethod(data).then(function(result) {
            // Read result of the Cloud Function.
            console.log(result);
            if(result.data.code === 200)
            {
                const redirectCallback = () => { history.push(ROUTES.SIGN_IN)};
                alertModal.successAlert(t("title_success"),t("msg_account_successfully_created"),redirectCallback);
            }
            else
            {
                alertModal.successAlert(t("title_error"),t("msg_error_on_registration"));
            }


        }).catch(function(error) {
            // Getting the Error details.
            console.log(error);
            alertModal.successAlert(t("title_error"),t("msg_error_on_registration"));
        });       
    };

    prepareData = () => {
        const { invitedUser, name, email, password, phone } = this.state;
        let dataItem = {};
        
        dataItem['authData'] = {
            email: email,
            emailVerified: false,
            password: password,
            displayName: name
        };
        if (phone > 0){
            dataItem['authData'].phone = phone
        }

        let groupArray = [];
        groupArray[0] = {
            id: invitedUser.group.id,
            isOwner: false,
            name: invitedUser.group.name,
            invitedBy: invitedUser.invitedBy,
            invitedDate: invitedUser.date
        };

        dataItem['userData'] = {
            fullName: name,
            groups: groupArray,
            phone : {
                mobile : phone,
                fix: ''
            },
            registrationDate : new Date(),
            name: utilName.getNames(name,(i18n.language === "hu" ? 'LFM' : 'FLM') )
        }
        dataItem['groupId'] = invitedUser.group.id;

        return dataItem;
    }


    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        console.log(event.target.name);
        console.log(event.target.value);
    };

    render() {
        const { t } = this.props;
        const { name, email, loading, password, repeatPassword  } = this.state;

        const isInvalid = password === '' || (password !== repeatPassword);
        
        return (
            <div>
                <Header as="h1" color="teal" dividing>{t("inviteRequestRegistration")}</Header>
                { loading ? (
                    <Loader active inline />
                ) : (
                        <Segment>
                            {/* <Form>
                                <Form.Group>
                                    <Form.Field inline required >
                                        <label >{t("name")}</label>
                                        <Input placeholder={t("name")} name="name" value={name} onChange={this.onChange} type="text" width={2} />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field inline required>
                                        <label>{t("email")}</label>
                                        <Input placeholder={t("email")} name="email" value={email} onChange={this.onChange} type="email" />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field inline required>
                                        <label>{t("password")}</label>
                                        <Input placeholder={t("password")} name="password" onChange={this.onChange} type="password" />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Field inline required>
                                        <label>{t("repeatPassword")}</label>
                                        <Input placeholder={t("repeatPassword")} name="repeatPassword" onChange={this.onChange} type="password" />
                                    </Form.Field>
                                </Form.Group>

                                <Button color='teal' disabled={isInvalid} type="submit" onClick={this.onSubmit}>{t("register")}</Button>
                            </Form> */}

                            <Form onSubmit={this.onHandleSubmit}>
                                <Input type="text" label={t("name")} placeholder={t("name")} name="name" onChange={this.handleChange} value={name} validators={['required']} errorMessages={['this field is required']} width={4} /> 
                                <Input type="text" label={t("email")} placeholder={t("email")} name="email" onChange={this.handleChange} value={email} validators={['required','isEmail']} errorMessages={['this field is required','Should be email']} width={4} disabled={true} />
                                {/* <Input type="password" label={t("password")} placeholder={t("password")} name="password" onChange={this.handleChange} value={password} validators={['required']} errorMessages={['this field is required']} width={4} /> */}
                                <Input type="password" label={t("password")} placeholder={t("password")} name="password" onChange={this.handleChange} value={password} validators={['required','matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$']} errorMessages={['this field is required','Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (#?!@$%^&*-)']} width={4} />  
                                <Input type="password" label={t("repeatPassword")} placeholder={t("repeatPassword")} name="repeatPassword" onChange={this.handleChange} value={repeatPassword} validators={['required']} errorMessages={['this field is required']} width={4} />
                                <Button color='teal' disabled={isInvalid} type="submit">{t("register")}</Button>
                            </Form>
                        </Segment>
                    )}
        </div>
        );
  }
}

export default withTranslation()(withFirebase(RequestInvitation));
