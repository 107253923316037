import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { withAuthorization } from '../../Session';

import { withTranslation  } from 'react-i18next';
import { Header, Loader, Table, Button, Dropdown, Grid, Form } from 'semantic-ui-react';
import { Datagrid, GridField } from '../../Elements/Datagrid';
import * as alertModal from "../../Elements/AlertMsg";

class GroupPurchases extends Component {

	constructor(props) {
		super(props);

		this.purchaseRef = this.props.firebase.db.collection('Purchase');
		this.groupsRef = this.props.firebase.db.collection('UserGroups');
		this.state = {
			loading: false,
			purchases: [],
			activePurchase: this.props.activePurchase
		};
	}

	componentDidMount() {
		this.setState({ 
			loading: true,
			activePurchase: this.props.activePurchase 
		});

		let purchaseQuery = this.purchaseRef;
		if( this.props.groupId)
		{
			purchaseQuery = purchaseQuery.where("group.id","==",this.props.groupId).orderBy("isActive","desc").orderBy("expiration","asc");
		}


		//get items
		purchaseQuery.onSnapshot(snapshot => {
			let purchases = [];
      		snapshot.forEach(doc =>{
				const data = doc.data(); 
        		let purchase = { ...data, uid: doc.id, packageName: data.package.name, nrExaminations: (data.package.totalExaminationsLimit - data.examCountTot ) + '/' + data.package.totalExaminationsLimit };        
				purchases.push(purchase);
			}
		);


		this.setState({
			purchases,
			activePurchase: this.props.activePurchase,
			loading: false
		});
    });
	}

	componentWillUnmount() {
		//this.unsubscribe();
	}

	onSetPackage = (event) =>{
		const { activePurchase } = this.state;
		const { t} = this.props;

		const data = { activePurchase: activePurchase}
		this.groupsRef.doc(this.props.groupId).set(data, { merge : true}).then(ref => {
			alertModal.successAlert(t("title_success"),t("msg_succesfully_set_active_package"));
			this.props.onActivePurchaseChange(activePurchase);
        }).catch(error =>{
            console.log(error);
            alertModal.errorAlert(t("title_error"),t("msg_error_set_active_package"));
        });
	}

	onChange = (event,data) => {
		this.setState({ [data.name]: data.value });
	};

	render() {
		const { purchases,activePurchase, loading } = this.state;
		const { t, i18n} = this.props;
		const activePurchaseOprions = purchases.filter(x => x.isActive === true).map(x => { return  {key: x.uid, text: x.packageName + ' - ' + x.nrExaminations + ' - ' + x.expiration.toDate().toLocaleDateString(i18n.language), value: x.uid}; })
		return (
			<div>
				{loading ? (
				<Loader active inline />
				) : (
					<React.Fragment>
						<Form>
							<Form.Group inline>
								<Form.Field>
									<label>{t("active_package")}</label>
								</Form.Field>
								<Form.Field>
									<Dropdown type="dropdown" selection options={activePurchaseOprions} name="activePurchase" value={activePurchase} onChange={this.onChange} />
								</Form.Field>
								<Form.Field>
									<Button primary onClick={this.onSetPackage} disabled={activePurchase === this.props.activePurchase}>{t("set_active_package")}</Button>
								</Form.Field>
							</Form.Group>
						</Form>
						
						<Datagrid data={purchases} uid="uid" indexed rowSelection={[{rule: ["uid",activePurchase], selection: { positive: true}}, {rule: ["isActive",false], selection: { negative: true, /*bgColor: "#fcdada", style: {"color": "red"}*/ }}]} >
							<GridField name="packageName" label={t("package")} />
							<GridField name="date" label={t("date")} type="date" language={i18n.language} />
							<GridField name="expiration" label={t("expirationDate")} type="date" language={i18n.language} />
							<GridField name="nrExaminations" label={t("nrOFExaminations")} />
							<GridField name="isActive" label={t("expired")} type="bool" value={false} />
							<GridField name="paymentType" label={t("paymentType")} />
							<GridField name="totalSalePrice" label={t("price")} />
							<GridField name="invoiceUrl" label={t("invoiceUrl")} type="url" parmas={{target: "_blank", rel: "noopener noreferrer"}} />
						</Datagrid>
					</React.Fragment>
				)}
			</div>
		);
	}
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withTranslation(),
)(GroupPurchases);
