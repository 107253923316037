import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../../components/Session';
import * as ADMIN_ROUTES from './admin_routes';

import { Header } from 'semantic-ui-react';

import { PackageList } from './Packages';
import PackageForm from './Packages/PackageForm';


import { ParameterSetList, ParameterSetForm } from './ParameterSets';
import AdminNavigation from './Navigation';
import RDSParemeterForm from './RDSParameters/RDSParemeterForm';
import RDSParameterList from './ParameterSets/RDSParameterList';

const AdminPage = () => (
	<div>
		<Header as="h2"> Admin </Header>
		<AdminNavigation />
		<Switch>
			<Route exact path={ADMIN_ROUTES.PACKAGE_LIST} component={PackageList} />
			<Route exact path={ADMIN_ROUTES.PACKAGE_ADD} component={PackageForm} />
			<Route exact path={ADMIN_ROUTES.PACKAGE_EDIT} component={PackageForm} />

			<Route exact path={ADMIN_ROUTES.PARAMETER_SET_LIST} component={ParameterSetList} />
			<Route exact path={ADMIN_ROUTES.PARAMETER_SET_ADD} component={ParameterSetForm} />
			<Route exact path={ADMIN_ROUTES.PARAMETER_SET_EDIT} component={ParameterSetForm} />

			<Route exact path={ADMIN_ROUTES.RDS_PARAMETER_LIST} component={RDSParameterList} />
			<Route exact path={ADMIN_ROUTES.RDS_PARAMETER_ADD} component={RDSParemeterForm} />
			<Route exact path={ADMIN_ROUTES.RDS_PARAMETER_EDIT} component={RDSParemeterForm} />

		</Switch>
	</div>
);

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(AdminPage);