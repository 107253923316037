import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../../../components/Session';

import { Button, Checkbox, Segment, Grid } from 'semantic-ui-react'
import {Form, Input, Dropdown, TextArea} from 'semantic-ui-react-form-validator';
import {  toast } from 'react-semantic-toasts';
import * as ADMIN_ROUTES from '../admin_routes';
import ColorInput from '../../../components/Elements/ColorInput';
import * as notify from "../../../components/Util/notification";



class RDSParameterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rdsParameterItem: {},
            ...props.location.state
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        //this.unsubscribe && this.unsubscribe();
    }

    onHandleSubmit = event => {
        const { history } = this.props;
		const { rdsParameterItem } = this.state;
		console.log(rdsParameterItem);
		this.getDocumentRef().set(rdsParameterItem,{ merge : true}).then(res => {
			history.goBack();
			notify.success("RDS parameter successfully saved");

        }).catch(e =>{
            console.log(e);
        });
        
    };

	getDocumentRef() {
        if( this.props.match.params.id )
        {
            return this.props.firebase.rdsParameters().doc(this.props.match.params.id);
        }
        else
        {
            return this.props.firebase.rdsParameters().doc();
        }
	}
	
    onChange = (event,data) => {
		console.log(data);
		const numberFields = ["density","disparity","bgDisparity","fps","pixelSize","schnellenE","noise"];
		let value = data.value;
		let svalue = parseFloat(value);
		if(numberFields.includes(data.name) && !value.endsWith('.') && !isNaN(svalue))
		{
			value = Number(data.value);
		}
		const newFields = { ...this.state.rdsParameterItem, [data.name]: value };
		this.setState({ rdsParameterItem: newFields });
	};
	
    onCheckboxChange = (event, data) =>{
        const newFields = { ...this.state.rdsParameterItem, [data.name]: data.checked };
        this.setState({ rdsParameterItem: newFields });
    };

    render() {
        const { rdsParameterItem } = this.state;

        return (
            <Form onSubmit={this.onHandleSubmit}>
				<Segment>
					<Input type="text" label="Name" placeholder="Name" name="name" onChange={this.onChange} value={rdsParameterItem.name} validators={['required']} errorMessages={['this field is required']} width={4}/>
					<Input type="text" label="OrderId" placeholder="OrderId" name="orderId" value={rdsParameterItem.orderId} validators={['required']} errorMessages={['this field is required']} width={4} disabled={true} />


					<Grid>
						<Grid.Row>
							<Grid.Column width={2}>
								<Input type="text" label="Density" placeholder="Density" name="density" onChange={this.onChange} value={rdsParameterItem.density} validators={['required','isFloat']} errorMessages={['this field is required','Should be float']} />
							</Grid.Column>
							<Grid.Column width={2}>
								<Input type="text" label="Disparity" placeholder="Disparity" name="disparity" onChange={this.onChange} value={rdsParameterItem.disparity} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} />
							</Grid.Column>
							<Grid.Column width={2}>
								<Input type="text" label="bgDisparity" placeholder="bgDisparity" name="bgDisparity" onChange={this.onChange} value={rdsParameterItem.bgDisparity} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} />
							</Grid.Column>
							<Grid.Column width={2}>
								<Input type="text" label="fps" placeholder="fps" name="fps" onChange={this.onChange} value={rdsParameterItem.fps} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} />
							</Grid.Column>
							<Grid.Column width={2}>
								<Input type="text" label="pixelSize" placeholder="pixelSize" name="pixelSize" onChange={this.onChange} value={rdsParameterItem.pixelSize} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} />
							</Grid.Column>
							<Grid.Column width={2}>
								<Input type="text" label="schnellenE" placeholder="schnellenE" name="schnellenE" onChange={this.onChange} value={rdsParameterItem.schnellenE} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} />
							</Grid.Column>
							<Grid.Column width={2}>
								<Input type="text" label="noise" placeholder="noise" name="noise" onChange={this.onChange} value={rdsParameterItem.noise} validators={['required','isFloat']} errorMessages={['this field is required','Should be float']} />
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Grid>
						<Grid.Row>
							<Grid.Column width={4}>
								<Segment compact>
									<Checkbox label="DRDC" name="isDRDC" onChange={this.onCheckboxChange} toggle checked={rdsParameterItem.isDRDC} />
								</Segment>
							</Grid.Column>
							<Grid.Column width={4}>
								<Segment compact>
									<Checkbox label="isDynamic" name="isDynamic" onChange={this.onCheckboxChange} toggle checked={rdsParameterItem.isDynamic} />
								</Segment>
							</Grid.Column>
							<Grid.Column width={4}>
								<Segment compact>
									<Checkbox label="isNoiseExcludeE" name="isNoiseExcludeE" onChange={this.onCheckboxChange} toggle checked={rdsParameterItem.isNoiseExcludeE} />
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<br/>
					<Grid>
						<Grid.Row>
							<Grid.Column width={4}>
								<ColorInput label="black" name="black" R={rdsParameterItem.black.R} G={rdsParameterItem.black.G} B={rdsParameterItem.black.B} onChange={this.onChange} />
							</Grid.Column>
							<Grid.Column width={4}>
								<ColorInput label="Red" name="red" R={rdsParameterItem.red.R} G={rdsParameterItem.red.G} B={rdsParameterItem.red.B} onChange={this.onChange}  />
							</Grid.Column>
							<Grid.Column width={4}>
								<ColorInput label="Green" name="green" R={rdsParameterItem.green.R} G={rdsParameterItem.green.G} B={rdsParameterItem.green.B} onChange={this.onChange}  />
							</Grid.Column>
							<Grid.Column width={4}>
								<ColorInput label="Yellow" name="yellow" R={rdsParameterItem.yellow.R} G={rdsParameterItem.yellow.G} B={rdsParameterItem.yellow.B} onChange={this.onChange} />
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<br/>
					<Button color='teal' type="submit">Save</Button> 
				</Segment>
                

                           
            </Form>
        );
  }
}

const condition = authUser => !!authUser;

export default compose(
	withAuthorization(condition),
  )(RDSParameterForm);
