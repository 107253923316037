export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const PASSWORD_FORGET = '/pw-forget';
export const REGISTER_BY_INVITE = "/registration/invite/:id";
export const REGISTER_BY_REQUEST = "/registration/request";

export const PACKAGES = "/packages";
export const PACKAGE_DETAIL = "/package/:id";

export const ACCOUNT = '/account';
export const PASSWORD_CHANGE = '/change-passwords';
export const PURCHASES = '/purchases';
export const GROUPS = '/groups';
export const GROUP_DETAIL = "/group/:id";
export const GROUP_INVITE_MEMBER = "/group_invite";
export const DOWNLOAD = '/downloads';
export const PROBABILITY_CALCULATOR = '/probability-calculator';

export const PATIENTS = "/patients";
export const PATIENT_DETAIL = "/patients/:id";
export const PATIENT_ADD = "/patients/add";
export const PATIENT_EDIT = "/patients/edit/:id";

export const ADMIN = '/admin';

export const NOAUTH_PAGES = [HOME, SIGN_IN,PASSWORD_FORGET,REGISTER_BY_REQUEST,REGISTER_BY_INVITE, PACKAGES, LANDING];
export const WORKGROUP_PAGES = [ACCOUNT,PASSWORD_CHANGE,PURCHASES,GROUPS,GROUP_DETAIL,GROUP_INVITE_MEMBER,DOWNLOAD,PROBABILITY_CALCULATOR, 
	PATIENTS, ADMIN];
