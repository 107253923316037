
import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import { Loader, Grid, Segment, Header } from 'semantic-ui-react';
import ExaminationList from '../Examintaions/ExaminationList';
import { withTranslation  } from 'react-i18next';
import i18n from "../../i18n";

const timeStampToDate = (timeStamp) => {
  return new Date( timeStamp.seconds*1000);
};

class PatientDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      patient: null,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.patient) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .patient(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
          patient: snapshot.data(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { patient, loading } = this.state;
    const { t } = this.props;

    return (
      <div>
        {loading ? (
          <Segment>
            <Loader active inline />
          </Segment> 
        ) : (
        <div>
          <Header as="h1" color="teal" dividing>{patient.fullName}</Header>
          <Segment>
            <Header size="small" dividing >{t("details")}</Header>
            <Grid columns={4}>
              <Grid.Row stretched>
                <Grid.Column textAlign="right">{t("dateOfBirth")}:</Grid.Column>
                <Grid.Column>{(typeof patient.birthday === "undefined") ? "" : timeStampToDate(patient.birthday).toLocaleDateString(i18n.language) }</Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column textAlign="right">{t("registrationDate")}:</Grid.Column>
                <Grid.Column>{(typeof patient.date === "undefined") ? "" : timeStampToDate(patient.date).toLocaleDateString(i18n.language) }</Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column textAlign="right">{t("registeredBy")}:</Grid.Column>
                <Grid.Column>{patient.registredBy}</Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column textAlign="right">{t("medicalId")}:</Grid.Column>
                <Grid.Column>{patient.medicalId}</Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column textAlign="right">{t("lastExaminationDate")}:</Grid.Column>
                <Grid.Column>{(typeof patient.lastExaminationDate === "undefined") ? "" : timeStampToDate(patient.lastExaminationDate).toLocaleDateString(i18n.language) }</Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column textAlign="right">{t("motherName")}:</Grid.Column>
                <Grid.Column>{patient.motherName}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment>
            <Header size="small" dividing>{t("examinations")}</Header>
            <ExaminationList examinations={patient.examinations} />
          </Segment>`
        </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(withFirebase(PatientDetail));
