import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../../../components/Session';
import * as ADMIN_ROUTES from './../admin_routes';

import { Button, Header, Loader} from 'semantic-ui-react';
import {Datagrid, GridField, GridActions,GridButton } from '../../../components/Elements/Datagrid';

class ParameterSetList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			parameterSets: [],
		};
	}

	componentDidMount() {
		this.setState({ loading: true });
		
		this.getItems();
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	getItems( ) {
		this.setState({ loading: true });

		this.unsubscribe = this.props.firebase
			.parameterSets()
			.onSnapshot(snapshot => {
				let parameterSets = [];

				snapshot.forEach(doc => {
					let data = doc.data();
					parameterSets.push({ 
						...data, 
						id: doc.id,
					});
				});

				this.setState({
					parameterSets,
					loading: false
				});
			});
	}

	render() {
		const { parameterSets, loading } = this.state;
		return (
			<div>
			<Header positive as="h2" dividing >Parameter sets</Header>

			<Button primary as={Link} to={{ pathname: ADMIN_ROUTES.PARAMETER_SET_ADD }} > Add new parameter set </Button>

			{loading ? (
				<Loader active inline />
			) : (
				<Datagrid data={parameterSets} uid="id" indexed >
					<GridField name="name" label="Name" />
					<GridField name="description" label="Description" />
					<GridField name="comment" label="Comment" />
					<GridField name="glassType" label="Glass type" />
					<GridField name="isActive" type="bool" />
					<GridActions label="Actions">
						<GridButton path={ADMIN_ROUTES.PARAMETER_SET_EDIT} params={{id:"id"}} title="Edit" state="parameterSetItem" positive={true} />
					</GridActions>
				</Datagrid>
			)}
			</div>
		);
	}
}

const condition = authUser => !!authUser;

export default compose(
	withAuthorization(condition),
  )(ParameterSetList);
