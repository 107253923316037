import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { Button, Icon, Header, Modal, Segment, Step} from 'semantic-ui-react'

import { withTranslation } from 'react-i18next';
import StepPage from './StepPage';
import * as BuyerTypes from '../../constants/buyer_types';

import * as ROUTES from '../../constants/routes';
import * as alertModal from "../Elements/AlertMsg";

const randomNumber = (min, max) => {  
    return Math.floor(Math.random() * (max - min + 1) + min); 
}


class PurchaseFormModal extends Component {

	constructor(props) {
		super(props);

		this.authUser = JSON.parse(localStorage.getItem('authUser'));
		this.initialStepNr = this.authUser !== null ? 1 : 0;
		this.state = {
			step: this.initialStepNr,
			loading: false,
 			values: { 
				purchase_name: this.authUser !== null ? this.authUser.fullName : "", 
				purchase_email: this.authUser !== null ? this.authUser.email : "" ,
				groupOptions: this.authUser !== null ? this.authUser.groups.filter(x => x.isOwner === true).map(x => { return  {key: x.id, text: x.name, value: x.id}; }) : [],
				purchase_buyer_type: this.authUser !== null && this.authUser.hasOwnProperty("customer_type")  ? Number(this.authUser.customer_type) : BuyerTypes.PERSON,
				company_name : this.authUser !== null && this.authUser.hasOwnProperty("invoiceData") ? this.authUser.invoiceData.company : "",
				country: this.authUser !== null && this.authUser.hasOwnProperty("invoiceData") ? this.authUser.invoiceData.country.toUpperCase() : "",
				region: this.authUser !== null && this.authUser.hasOwnProperty("invoiceData") ? this.authUser.invoiceData.state : "",
				city: this.authUser !== null && this.authUser.hasOwnProperty("invoiceData") ? this.authUser.invoiceData.city : "",
				postalCode: this.authUser !== null && this.authUser.hasOwnProperty("invoiceData") ? this.authUser.invoiceData.zip : "",
				street: this.authUser !== null && this.authUser.hasOwnProperty("invoiceData") ? this.authUser.invoiceData.address : "",
				vat_number: this.authUser !== null && this.authUser.hasOwnProperty("companyData") ? this.authUser.companyData.vat_number : "",
				registration_number: this.authUser !== null && this.authUser.hasOwnProperty("companyData") ? this.authUser.companyData.registration_number : "",
				official_represented_by: this.authUser !== null && this.authUser.hasOwnProperty("companyData") ? this.authUser.companyData.official_represented_by : "",
				official_representer_role: this.authUser !== null && this.authUser.hasOwnProperty("companyData") ? this.authUser.companyData.official_representer_role : "",
				company_email: this.authUser !== null && this.authUser.hasOwnProperty("companyData") ? this.authUser.companyData.company_email : "",
				bank_account: this.authUser !== null && this.authUser.hasOwnProperty("companyData") ? this.authUser.companyData.bank_account : "",
				verification_code: "",
				generatedCode: ""
			}
		};

	}

	componentDidMount() {
		if(this.props.packageItem)
		{	
			const newFields = { ...this.state.values, packageItem: this.props.packageItem };
        	this.setState({ values: newFields });
		}
	}

	componentWillUnmount() {
	}
	
	nextStep = () => {
        const { step } = this.state
        this.setState({
            step : step + 1
        })
	}
	
	onVerifyEmail = () => {
		const { values } = this.state;
		const { t,i18n,history } = this.props;
		const generated_code = randomNumber(10000000,99999999);
		const data = {
			email: values.purchase_email,
			verificationCode: generated_code,
			lang: i18n.language
		};
		console.log(data);
		this.setState({ loading: true });
		var sendVerificationEmailCodeMethod = this.props.firebase.functions.httpsCallable("user-emailVerfication");
		sendVerificationEmailCodeMethod(data).then(result => {
			// Read result of the Cloud Function.
			console.log(result);

			if( result.data.code === 201) // user have already account with this email
			{
				const redirectCallback = () => { history.push(ROUTES.SIGN_IN)};
				alertModal.successAlert(t("title_success"),t("msg_already_account_login"),redirectCallback);
			}
			else if( result.data.code === 202) // email verification code sent
			{
				const verificatinData = {
					generatedCode: generated_code,
					verification_code: ''
				}
				const newFields = { ...this.state.values, ...verificatinData  };
        		this.setState({ values: newFields });
				alertModal.successAlert(t("title_success"),t("msg_email_confirmation"));
			}
			else
            {
                alertModal.successAlert(t("title_error"),t("msg_error_email_verification"));
			}
			this.setState({ loading: false });
		}).catch(error => {
				// Getting the Error details.
				console.log(error);
		});
		return false;
	}


    prevStep = () => {
        const { step } = this.state
        this.setState({
            step : step - 1
        })
    }
	
	onHandleSubmit = event => {
		this.handlePurchaseItem();
	}

	handlePurchaseItem(){
		this.props.onFinish();
		let data = this.prepareData();
		console.log(data);
		
		var startPaymentMethod = this.props.firebase.functions.httpsCallable("payment-startSimplePay");
		startPaymentMethod(data).then(function(result) {
				// Read result of the Cloud Function.
				console.log(result);
				window.location = result.data.paymentUrl;
		}).catch(function(error) {
				// Getting the Error details.
				console.log(error);
		});
		this.props.onClose();
	}

	prepareData() {
		const { values} = this.state
		const { packageItem, purchase_name, purchase_email,purchase_group , groupOptions } = values;
		const { i18n } = this.props;

		const prices = this.getPrices();
		let data = {};
		data.packageData = {};
		//data.packageData.unitPrice = Number(packageItem.unitPrice);
		data.packageData.id = packageItem.id;
		data.packageData.name = packageItem.name;
		data.packageData.terminationNrMonth = Number(packageItem.termMonth);
		data.packageData.description = packageItem.description;
		data.packageData.unitPrice = prices.unitPrice;
		data.packageData.tax = prices.vat;
		data.packageData.vatPrice = prices.vatPrice;
		data.packageData.totalPrice = prices.totalPrice;

		data.user = {};
		data.user.id = this.authUser !== null ? this.authUser.uid : "new";
		data.user.name = purchase_name;
		data.user.invoiceData = {
			name: purchase_name,
			company: values.purchase_buyer_type === BuyerTypes.COMPANY ? values.company_name : "",
			country: values.country.toLowerCase(),
			state: values.region,
			city: values.city,
			zip: values.postalCode,
			address: values.street,
			phone: ""
		};
		data.user.customer_type = values.purchase_buyer_type;
		if(values.purchase_buyer_type === BuyerTypes.COMPANY)
		{
			data.user.companyData = {
				company_name: values.company_name,
				vat_number: values.vat_number,
				registration_number: values.registration_number,
				official_represented_by: values.official_represented_by,
				official_representer_role: values.official_representer_role,
				company_email: values.company_email,
				bank_account: values.bank_account,
			};
		}

		data.email = purchase_email;
		data.isActive = true;

		data.group = {};
		data.group.id = groupOptions.find(x => x.value === purchase_group && !x.key.startsWith("new-"))  ? purchase_group : "new";
		data.group.name = groupOptions.find(x => x.value === purchase_group).text;

		data.app_origin_url = window.location.origin;
		data.language = i18n.language;
		return data;
	}

	handleGroupAddition = (e, data) => {
		let values = this.state.values;
		const newFields = { ...values, groupOptions: [{ key : "new-" + data.value, text: data.value, value: data.value}, ...values.groupOptions], [data.name]: data.value };
		this.setState({ values: newFields });
	}

	onChange = (event,data) => {
		if(data.type === "dropdown" || data.type === "change")
		{
			const newFields = { ...this.state.values, [data.name]: data.value };
        	this.setState({ values: newFields });
		}
		else if(data.type === "checkbox")
		{
			const newFields = { ...this.state.values, [data.name]: data.checked };
        	this.setState({ values: newFields });
		}
		else if(data.type === "radio")
		{
			const newFields = { ...this.state.values, [data.name]: data.value };
        	this.setState({ values: newFields });
		}
		else
		{
			const newFields = { ...this.state.values, [event.target.name]: event.target.value };
        	this.setState({ values: newFields });
		}
	};
	
	getPrices = () => {
		const { values } = this.state;
		const unitPrice = parseFloat(this.props.packageItem.unitPrice);
		let tax = 0.00;
		if( values.purchase_buyer_type === BuyerTypes.PERSON || values.country.toUpperCase() === "HU")
		{
			tax = 27.00;
		}
		const vatPrice = (unitPrice * tax) /100;
		const totalPrice = unitPrice + vatPrice;
		return {unitPrice: unitPrice, vat: tax, vatPrice: vatPrice, totalPrice: totalPrice};
	}

	render() {
		const { t } = this.props;
		const { loading } = this.state;
		const isInvalid =  !this.state.values.termsAgree || !this.state.values.correct_verified_value;
		console.log(this.state.step);
		const validatedEmail = (this.state.values.verification_code.length === 8 && this.state.values.generatedCode.toString() === this.state.values.verification_code);
		return (
			<Modal closeIcon open={this.props.open} onClose={() => this.props.onClose()} closeOnEscape={false} closeOnDimmerClick={false} size='large'>
				<Header content={t("purchase")} />
				<Modal.Content>
					<Step.Group ordered size='tiny'>
						{
							this.authUser === null ? (
								<Step active ={this.state.step === 0} completed={this.state.step > 0}>
									<Step.Content>
										<Step.Title>{t("verification_email")}</Step.Title>
										<Step.Description>{t("enter_verification_code")}</Step.Description>
									</Step.Content>
								</Step>
							) : ""
						}
						
						<Step active ={this.state.step === 1} completed={this.state.step > 1}>
							<Step.Content>
								<Step.Title>{t("user_data")}</Step.Title>
								<Step.Description>{t("enter_user_data")}</Step.Description>
							</Step.Content>
						</Step>
						<Step active ={this.state.step === 2} completed={this.state.step > 2}>
							<Step.Content>
								<Step.Title>{t("invoice_data")}</Step.Title>
								<Step.Description>{t("enter_invoice_data")}</Step.Description>
							</Step.Content>
						</Step>
						<Step active ={this.state.step === 3} completed={this.state.step > 3}>
							<Step.Content>
								<Step.Title>{t("confirm_checkout")}</Step.Title>
							</Step.Content>
						</Step>
					</Step.Group>
					<Segment loading={loading}>
						<StepPage step={this.state.step} nextStep={this.nextStep} handleChange = {this.onChange} values={this.state.values} handleGroupAddition={this.handleGroupAddition} handlePrices={() => this.getPrices()} handleVerifyEmail={this.onVerifyEmail}/>
					</Segment>
						
				</Modal.Content>
				<Modal.Actions>
					<Button color='red' onClick={() => this.props.onClose()}>
						<Icon name='remove' /> {t("cancel")}
					</Button>
					<Button onClick={this.prevStep} warning disabled={this.state.step === this.initialStepNr}>
						<Icon name='left chevron' /> {t("back")}
					</Button>
					<Button type="submit" form="dataForm" disabled={this.state.step === 3 || (this.state.step === 0 && !validatedEmail)}>
						{t("continue")} <Icon name='right chevron' />
					</Button>
					<Button type="submit" onClick={this.onHandleSubmit} color='green' disabled={isInvalid} >
						<Icon name='checkmark' /> {t("pay")}
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

export default withTranslation()(withFirebase(PurchaseFormModal));