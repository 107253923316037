function getNames(name, format){
	let splitStringArr = name.split(' ');
	let names = {};
	names['First'] = '';
	names['Last'] = '';
	names['Middle'] = [];

	
	if( format === 'LFM') // last first middle
	{
		names['First'] = splitStringArr[1];
		names['Last'] = splitStringArr[0];
		names['Middle'] = splitStringArr.slice(2,splitStringArr.length);
	}
	else //first middle last
	{
		names['First'] = splitStringArr[0];
		names['Last'] = splitStringArr[splitStringArr.length-1];
		names['Middle'] = splitStringArr.slice(1,splitStringArr.length-1);
	}
	return names;
};

export { getNames };