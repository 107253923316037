import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../../../components/Session';
import * as ADMIN_ROUTES from './../admin_routes';

import { Button, Header, Loader} from 'semantic-ui-react';
import {Datagrid, GridField, GridActions,GridButton } from '../../../components/Elements/Datagrid';

class PackageList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			packages: [],
		};
	}

	componentDidMount() {
		this.setState({ loading: true });
		
		this.getItems();
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	getItems( ) {
		this.setState({ loading: true });

		this.unsubscribe = this.props.firebase
			.packages()
			.onSnapshot(snapshot => {
				let packages = [];

				snapshot.forEach(doc => {
					let data = doc.data();
					packages.push({ 
						...data, 
						id: doc.id,
						parameterSets : data.hasOwnProperty('setNamesRef') ? doc.data().setNamesRef.map(x => x.id) : [],
						setNamesRef : null,
					});
				});

				this.setState({
					packages,
					loading: false
				});
			});
	}

	render() {
		const { packages, loading } = this.state;
		return (
			<div>
			<Header as="h2" dividing >Packages</Header>

			<Button primary={true} as={Link} to={{ pathname: ADMIN_ROUTES.PACKAGE_ADD }}> Add new package </Button>
			

			{loading ? (
				<Loader active inline />
			) : (
				<React.Fragment>
					<Datagrid data={packages} uid="id" indexed >
						<GridField name="billingoId" label="Billingo Id" />
						<GridField name="name" label="name" />
						<GridField name="description" label="description" />
						<GridField name="totExamLimit" label="Total examination" />
						<GridField name="unitPrice" />
						<GridField name="termMonth" />
						<GridField name="includeVisus" type="bool" />
						<GridField name="isActive" type="bool" />
						<GridField name="isPublished" type="bool" />

						<GridActions label="Actions">
							<GridButton path={ADMIN_ROUTES.PACKAGE_EDIT} params={{id:"id"}} title="Edit" state="pkgItem" positive={true} />
						</GridActions>
					</Datagrid>
				</React.Fragment>
				
			)}
			</div>
		);
	}
}

const condition = authUser => !!authUser;

export default compose(
	withAuthorization(condition),
  )(PackageList);
