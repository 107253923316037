import React, { Component } from 'react';
import {Form, Input} from 'semantic-ui-react-form-validator';
import { withTranslation } from 'react-i18next';
import { Button, Grid, Icon, Message, Segment } from 'semantic-ui-react';

class VerificationEmailForm extends Component
{
    
    renderVerificationCode = () =>{
        const {t, values } = this.props;
        if( values.generatedCode !== null && values.generatedCode.toString().length > 0 )
        {
            let message = "";
            if( values.verification_code !== null && values.verification_code.length >= 8)
            {
                if( values.generatedCode.toString() === values.verification_code)
                {
                    message = <Grid.Column width={6} verticalAlign="bottom" >
                        <Message positive><Icon name='checkmark'/>{t("msg_valid_verification_code")}</Message>
                    </Grid.Column>;
                }
                else
                {
                    message = <Grid.Column width={6} verticalAlign="bottom">
                        <Message negative><Icon name='remove'/>{t("msg_invalid_verification_code")}</Message>
                    </Grid.Column>;
                }
            }
            
            return <React.Fragment>
                <Grid.Row>
                    <Grid.Column width={4} >
                        <Input type="text" label={t("verification_code")} placeholder={t("verification_code")} name="verification_code" onChange={this.props.handleChange} value={values.verification_code} validators={['required']} errorMessages={['this field is required']} width={16} />
                    </Grid.Column>
                    {message}
                </Grid.Row>
            </React.Fragment>;
        }
        else
            return "";
    }

    render(){
        const {t, values } = this.props;
        return(
            <Form id="dataForm" onSubmit={this.props.nextStep} >
                <h2>{t("verification_email")}</h2>
                <Segment basic>
                    {t("request_email")}
                </Segment>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}><Input type="text" label={t("ph_request_email")} placeholder={t("ph_request_email")} name="purchase_email" onChange={this.props.handleChange} value={values.purchase_email} validators={['required','isEmail']} errorMessages={['this field is required','Should be email']} width={16} /></Grid.Column>
                        <Grid.Column verticalAlign='bottom' width={6} ><Button type="button" color='teal' onClick={this.props.handleVerifyEmail}>{t("verify_email")}</Button></Grid.Column>
                    </Grid.Row>
                    {
                        this.renderVerificationCode()
                    }
                </Grid>
            </Form>
        )
    }
}

export default withTranslation()(VerificationEmailForm);