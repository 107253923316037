
import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';

import { withTranslation  } from 'react-i18next';
import { Header, Loader, Table, Button, Icon, Popup,Confirm, Message, Segment } from 'semantic-ui-react';

import PatientSearchForm from './PatientSearchForm';
import i18n from "../../i18n";
import PatientImport from './PatientImport';
import withGroup from '../Session/withGroup';
import * as alertModal from "../Elements/AlertMsg";
import * as SETTINGS from '../../constants/settings';
import { _refWithOptions } from 'firebase-functions/lib/providers/database';

const itemsPerPage = 10;

class PatientList extends Component {

  constructor(props) {
    super(props);
    this.patientRef = this.props.firebase.patients().where("group.id","==", this.props.workgroup.id).where("isActive","==",true);
    this.patientQuery = this.patientRef;
    this.state = {
      loading: false,
      patients: [],
      totalPages: 0,
      currentPage: 1
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    //console.log(this.props);
    if( this.props.workgroup)
    {
      this.setState({ groupId: this.props.workgroup.id });
    }


    //get items
    this.getItems();
  }

  componentWillUnmount() {
    //this.unsubscribe();
  }
  
  onSearchPatients = searchFields => {
      this.setState({
        loading: true, 
        searchParams: searchFields,
        currentPage : 1 
      });

      this.buildQuery(searchFields);

      //get items
      this.getItems();
  };

  getItems( ) {
    this.setState({ loading: true });
    //get results
    this.patientQuery
    //.orderBy('date', 'desc')
    .limit(itemsPerPage)
    .onSnapshot(snapshot => {
      let patients = [];
      snapshot.forEach(doc =>{
        let patientT = { ...doc.data(), uid: doc.id };
        let patient = {};
        patient.fullName = patientT.fullName;
        patient.date = patientT.date;
        patient.birthday = patientT.birthday;
        patient.uid = patientT.uid;
        patient.registredBy = patientT.user.name;
        patient.medicalId = patientT.medicalCardNumber;
        patient.motherName = patientT.motherName;
        patient.lastExaminationDate = ((typeof patientT.examinations !== "undefined") && (patientT.examinations.length)) > 0 ? (patientT.examinations.reduce((a, b) => (a.date > b.date ? a : b))).date : undefined;
        if(typeof patientT.examinations !== "undefined"){
          
          //patientT.examinations.forEach(function(v){ delete v.ref });
          patient.examinations = patientT.examinations;
        }
        
        
        patients.push(patient);
      }
      );

      this.setState({
        patients,
        loading: false
      });
    });

  }

  buildQuery = (searchParams) =>{
      const { firstName, middleName, lastName, medicalCardId,birthDate,lastExaminationDate } = searchParams;
        
      this.patientQuery = this.patientRef;
      if (firstName !== '')
      {
        this.patientQuery = this.patientQuery.where('lc_name.First','==',firstName.toLowerCase());
      }
      if (middleName !== '')
      {
        this.patientQuery = this.patientQuery.where('lc_name.Middle','array-contains',middleName.toLowerCase());
      }
      if (lastName !== '')
      {
        this.patientQuery = this.patientQuery.where('lc_name.Last','==',lastName.toLowerCase());
      }
      if (medicalCardId !== '')
      {
        this.patientQuery = this.patientQuery.where('medicalCardNumber','==',medicalCardId);
      }
      if (birthDate != null && birthDate !== '')
      {
        let dueDate = new Date(birthDate);
        dueDate.setHours(0, 0, 0, 0);
        //new Date(new Date().setDate(new Date().getDate() + 1))
        //this.patientQuery = this.patientQuery.where('birthday','>=',new Date(birthDate)).where('birthday','<',dueDate);
        const dateStr = dueDate.toISOString().split("T")[0];
        console.log(dateStr);
        this.patientQuery = this.patientQuery.where('birthdayStr','==',dateStr);
      }
      
      if (lastExaminationDate != null && lastExaminationDate !== '')
      {
        let dueDate = new Date(lastExaminationDate);
        dueDate.setHours(0, 0, 0, 0);
        //new Date(new Date().setDate(new Date().getDate() + 1))
        //this.patientQuery = this.patientQuery.where('lastExaminationDate','>=',new Date(lastExaminationDate)).where('lastExaminationDate','<',dueDate);
        this.patientQuery = this.patientQuery.where('lastExaminationDateStr','==',dueDate.toISOString().split("T")[0]);
      }


      //this.patientQuery.orderBy('date').startAt((pageNumber - 1) * itemsPerPage).limit(itemsPerPage);
  }

  getTotalResult = () => {
    let totalResult = 0;
    this.patientQuery.limit(100).onSnapshot(snapshot => { 
      totalResult = snapshot.size;
      this.setState({
        totalPages: Math.ceil(totalResult/itemsPerPage),
        patientCount: totalResult
      });
    });

  }

  showConfirmDelete(patientData){
		this.setState({ 
			openDeleteConfirm: true,
			patient: patientData
		});
  }
  
  handleCancelDelete = () => this.setState({ openDeleteConfirm: false, patient: null });

  handleConfirmDelete() {
    const { t } = this.props;
    const { patient } = this.state;
    
    this.deletePatient(patient).then(x =>{
		alertModal.successAlert(t("title_success"),t("msg_succesfully_delete_patient"));
		this.setState({ openDeleteConfirm: false, patient: null });
	}).catch(e =>{
		console.log(e)
		alertModal.errorAlert(t("title_error"),t("msg_error_delete_patient"));
	});
  }

  deletePatient(patient) {
		if( patient.examinations.length > 0)
		{
			const patientData = {
				name : {
					First: SETTINGS.ANONYMOUS_NAME,
					Middle: [SETTINGS.ANONYMOUS_NAME],
					Last: SETTINGS.ANONYMOUS_NAME,
        },
        lc_name : {
					First: SETTINGS.ANONYMOUS_NAME.toLowerCase(),
					Middle: [SETTINGS.ANONYMOUS_NAME.toLowerCase()],
					Last: SETTINGS.ANONYMOUS_NAME.toLowerCase(),
        },
        lc_motherName: SETTINGS.ANONYMOUS_NAME.toLowerCase(),
				fullName: SETTINGS.ANONYMOUS_NAME,
				motherName: SETTINGS.ANONYMOUS_NAME,
        isDeleted: true,
        isActive: false,
				medicalCardNumber: patient.uid
			}
			return this.props.firebase.patient(patient.uid).set(patientData, { merge : true})
		}
		else
		{
			return this.props.firebase.patient(patient.uid).delete();
		}
  }

  render() {
    const { patients, loading } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Header as="h1" color="teal" dividing>{t("patients")}</Header>

        <Button
                      primary
                      as={Link}
                      to={{
                        pathname: `patients/add`
                      }}
                    >
                      {t("add")}
        </Button>
        <Segment>
          <PatientImport />
        </Segment>
        
        <Segment>
          <PatientSearchForm onSearch={this.onSearchPatients} />
        </Segment>
        
        { patients != null && patients.length >= itemsPerPage ? 
          <Message warning>{(t("msg_patient_search_information")).replace("[X]",itemsPerPage)}</Message> : ""
        }
        
        {loading ? (
          <Loader active inline />
        ) : (
          <Table singleLine striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("fullName")}</Table.HeaderCell>
                <Table.HeaderCell>{t("dateOfBirth")}</Table.HeaderCell>
                <Table.HeaderCell>{t("registrationDate")}</Table.HeaderCell>
                <Table.HeaderCell>{t("registeredBy")}</Table.HeaderCell>
                <Table.HeaderCell>{t("medicalId")}</Table.HeaderCell>
                <Table.HeaderCell>{t("lastExaminationDate")}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {patients.map((patient, i) => (
                <Table.Row key={patient.uid}>
                  <Table.Cell><Link to={{pathname: ROUTES.PATIENT_DETAIL.replace(':id',patient.uid),state: { patient },}}>{patient.fullName}</Link></Table.Cell>
                  <Table.Cell>{(typeof patient.birthday === "undefined") ? "" : patient.birthday.toDate().toLocaleDateString(i18n.language) }</Table.Cell>
                  <Table.Cell>{(typeof patient.date === "undefined") ? "" : patient.date.toDate().toLocaleDateString(i18n.language) }</Table.Cell>
                  <Table.Cell>{patient.registredBy}</Table.Cell>
                  <Table.Cell>{patient.medicalId}</Table.Cell>
                  <Table.Cell>{(typeof patient.lastExaminationDate === "undefined") ? "" : patient.lastExaminationDate.toDate().toLocaleDateString(i18n.language) }</Table.Cell>
                  <Table.Cell>
                    <Button
                      primary
                      as={Link}
                      to={{
                        pathname: `${ROUTES.PATIENTS}/${patient.uid}`,
                        state: { patient },
                      }}
                    >
                      {t("details")}
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    <Button primary as={Link} to={{pathname: ROUTES.PATIENT_EDIT.replace(':id',patient.uid),state: { patient },}}>{t("edit")}</Button>
                  </Table.Cell>
                  <Table.Cell>
                  <Popup
                    trigger={
                      <div style={{ display: "inline-block" }}>
                        <Button color='red' onClick={()=>this.showConfirmDelete(patient)} ><Icon name='remove' />{t("delete")}</Button>
                      </div>
                    }
                    content={ patient.examinations.length > 0 ? t("msg_delete_patient_to_anonymus"): t("msg_delete_patient_forever") }
                  />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='9' >
                  </Table.HeaderCell>
                </Table.Row>
                
            </Table.Footer>
          </Table>
        )}
        <Confirm
            open={this.state.openDeleteConfirm}
            header={t("deleteConfirmHeader")}
            cancelButton={t("cancel")}
            confirmButton={t("ok")}
            content={t("deleteConfirmPatient")}
            onCancel={this.handleCancelDelete}
            onConfirm={() => this.handleConfirmDelete()}
          />
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withGroup(condition),
  withTranslation(),
)(PatientList);

//export default withTranslation()(withFirebase(PatientList));
