import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../../../components/Session';

import { Button, Checkbox, Segment } from 'semantic-ui-react'
import {Form, Input, Dropdown, TextArea} from 'semantic-ui-react-form-validator';
import {  toast } from 'react-semantic-toasts';
import * as ADMIN_ROUTES from '../admin_routes';
 

const intervals = [ "Month","Day","Week" ];
const intervalOptions = intervals.map(x => {
    return { key: x, value: x,  text: x };
});

class PackageForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pkgItem: {},
            selectedParameterSets : [],
            ...props.location.state
        };
    }

    componentDidMount() {
        if(this.state.pkgItem.parameterSets)
        {
            this.setState({
                selectedParameterSets : this.state.pkgItem.parameterSets
            });
        }


        this.unsubscribe = this.props.firebase
            .parameterSets()
            .where("isActive","==", true)
			.onSnapshot(snapshot => {
				let parameterSetOptions = [];

				snapshot.forEach(doc =>
					parameterSetOptions.push({ 
                        key: doc.id,
                        text: doc.data().name,
                        value: doc.id,
                        description: doc.description
                    })
				);

				this.setState({
					parameterSetOptions
				});
			});
    }

    componentWillUnmount() {
        //this.unsubscribe && this.unsubscribe();
    }

    onHandleSubmit = event => {
        const { history } = this.props;
        const { pkgItem, selectedParameterSets } = this.state;
        pkgItem.setNamesRef = this.getParameterSetRefArray(selectedParameterSets);
        console.log(pkgItem);
        if( !pkgItem.hasOwnProperty("includeVisus"))
        {
            pkgItem.includeVisus = false;
        }

        this.getPackageDocumentRef().set(pkgItem,{ merge : true}).then(res => {
            history.push(ADMIN_ROUTES.PACKAGE_LIST);
            toast(
                {
                    title: 'Info Toast',
                    description: <p>This is a Semantic UI toast</p>,
                    time: 5000,
                    type: 'success'
                });
        }).catch(e =>{
            console.log(e);
        });
    };

    onChange = (event,data) => {
        let value = data.value;
        if( data.type === "numeric")
        {
            value = Number(data.value);
        }

        const newFields = { ...this.state.pkgItem, [data.name]: value };
        this.setState({ pkgItem: newFields });

        //this.setState({ [event.target.name]: event.target.value });
    };

    getPackageDocumentRef() {
        console.log(this.props.match.params.id);
        if( this.props.match.params.id )
        {
            return this.props.firebase.packages().doc(this.props.match.params.id);
        }
        else
        {
            return this.props.firebase.packages().doc();
        }
    }

    getParameterSetRefArray( selectedParameterSets ) {
        return selectedParameterSets.map(x => this.props.firebase.parameterSet(x));
    }

    onDropdownChange = (e, { value }) =>{
        this.setState({ selectedParameterSets: value });
    };

    onCheckboxChange = (event, data) =>{
        const newFields = { ...this.state.pkgItem, [data.name]: data.checked };
        this.setState({ pkgItem: newFields });
    };

    render() {
        const { pkgItem } = this.state;

        return (
            <Form onSubmit={this.onHandleSubmit}>
                <Input type="text" label="Name" placeholder="Name" name="name" onChange={this.onChange} value={pkgItem.name} validators={['required']} errorMessages={['this field is required']} width={4}/>
                <Input type="text" label="unitPrice" placeholder="unitPrice" name="unitPrice" onChange={this.onChange} value={pkgItem.unitPrice} validators={['required','isFloat']} errorMessages={['this field is required','Should be float']} width={4} />
                <Input type="numeric" label="Examination limit" placeholder="totExamLimit" name="totExamLimit" onChange={this.onChange} value={pkgItem.totExamLimit} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} width={4} />
                <Input type="numeric" label="termMonth" placeholder="termMonth" name="termMonth" onChange={this.onChange} value={pkgItem.termMonth} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} width={4} />
 {/*                <Dropdown
                    label="Time unit"
                    name='timeUnit'
                    selection
                    options={intervals}
                    value={pkgItem.timeUnit}
                    onChange={this.onDropdownChange}
                /> */}

                <Dropdown
                    label="Parameter set"
                    name='parameterSets'
                    placeholder='Parameter set'
                    fluid
                    multiple
                    search
                    selection
                    options={this.state.parameterSetOptions}
                    value={this.state.selectedParameterSets}
                    onChange={this.onDropdownChange}
                />
                
                <Segment compact>
                    <Checkbox 
                        label="Include visus" 
                        name="includeVisus" 
                        onChange={this.onCheckboxChange} 
                        toggle  
                        checked={pkgItem.includeVisus} 
                    />
                </Segment>


                <TextArea
                    label="Description"
                    name = 'description'
                    validators={['required']}
                    errorMessages={['CAnnot Be empty']}
                    value={pkgItem.description}
                    onChange={this.onChange}
                />

                <Segment compact>
                    <Checkbox 
                        label="Active" 
                        name="isActive" 
                        onChange={this.onCheckboxChange} 
                        toggle  
                        checked={pkgItem.isActive} 
                    />
                </Segment>
                <Segment compact>
                    <Checkbox 
                        label="Published" 
                        name="isPublished" 
                        onChange={this.onCheckboxChange} 
                        toggle  
                        checked={pkgItem.isPublished} 
                    />
                </Segment>

                <Button color='teal' type="submit">Save</Button>            
            </Form>
        );
  }
}

const condition = authUser => !!authUser;

export default compose(
	withAuthorization(condition),
  )(PackageForm);

//export default withTranslation()(withFirebase(PatientForm));
