function encode(data)
{
    return Buffer.from(JSON.stringify(data)).toString('base64');
}

function decode( str )
{
    return JSON.parse(Buffer.from(str, 'base64').toString('ascii'));
}

module.exports = { encode, decode};