import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { withTranslation, useTranslation } from 'react-i18next';

import {
  Grid,
  Form,
  Header,
  Button,
  Message,
  Segment,
} from 'semantic-ui-react';

const PasswordForgetPage = () => {
  const { t } = useTranslation();
  return <div>
      <Header as="h1" color="teal" dividing>{t("password_forget")}</Header>
      <Segment>
        <PasswordForgetForm />
      </Segment>
    </div>
};

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const { t } = this.props;

    const isInvalid = email === '';

    return (
      <div>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        <Form onSubmit={this.onSubmit}>
          <Form.Field>
            <label>{t("email")}</label>
            <input
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder={t("email")}
              width={4}
            />
          </Form.Field>
          <Button color="teal" disabled={isInvalid} type="submit">
            {t("reset_my_password")}
          </Button>
        </Form>
      </div>
    );
  }
}

const PasswordForgetLinkItem = () => {
  const { t } = useTranslation();
  return <Link to={ROUTES.PASSWORD_FORGET}>{t("forgot_password_link")}</Link>
};

export default withTranslation()(PasswordForgetPage);

//const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
const PasswordForgetForm = withTranslation()(withFirebase(PasswordForgetFormBase));
const PasswordForgetLink = withTranslation()(PasswordForgetLinkItem);

export { PasswordForgetForm, PasswordForgetLink};
