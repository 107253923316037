import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';

import {Button, Header, Segment, Message } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import {Form, Input } from 'semantic-ui-react-form-validator';
import { withFirebase } from '../Firebase';
import { withTranslation  } from 'react-i18next';
import * as alertModal from "../Elements/AlertMsg";
import * as ROUTES from '../../constants/routes';
import * as SETTINGS from '../../constants/settings';
 

const INITIAL_STATE = {
    passwordOld: '',
    passwordNew: '',
    passwordNewRepeat: '',
    error: null,
    loading: false
};


class ChangePasswordForm extends Component {
    constructor(props) {
        super(props);

        this.authUser = JSON.parse(localStorage.getItem('authUser'));
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
   
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onHandleSubmit = event => {
        const { t, history } = this.props;
        this.setState({
            error: null
        });
        const { oldPassword,newPassword,repeatNewPassword, error } = this.state;

        this.setState({ loading: true });
        this.props.firebase.changePassword(oldPassword,newPassword).then(result => {
            console.log(result);
            alertModal.successAlert(t("title_success"),t("msg_change_password_successfull"));
            this.setState({ loading: false });
            history.push(ROUTES.LANDING);
        }).catch(error => {
            //console.log(error);
            let errorMsg = error.message
            if (error.code === 'auth/wrong-password') {
                errorMsg = t("msg_wrong_password");
            }

            this.setState({
                error: errorMsg
            });
            alertModal.errorAlert(t("title_error"),errorMsg);
            this.setState({ loading: false });
        });
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { t } = this.props;
        const { oldPassword,newPassword,repeatNewPassword, error, success, loading } = this.state;

        const isInvalid = (newPassword === '' || repeatNewPassword === '' ) || (newPassword !== '' && repeatNewPassword !== '' && newPassword !== repeatNewPassword);

        return (
            <div>
                <Header as="h1" color="teal" dividing>{t("changePassword")}</Header>
                {/* <Segment>
                    {error && (
                        <Message negative>
                            <p>{error}</p>
                        </Message>
                    )}
                    <Form>
                        <Form.Group>
                            <Form.Field inline required >
                                <label >{t("oldPassword")}</label>
                                <Input placeholder={t("oldPassword")} name="oldPassword" onChange={this.onChange} type="password" width={2} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field inline required>
                                <label>{t("newPassword")}</label>
                                <Input placeholder={t("newPassword")} name="newPassword" onChange={this.onChange} type="password" />
                            </Form.Field> 
                        </Form.Group>
                        <Form.Group >
                            <Form.Field inline required>
                                <label>{t("repeatNewPassword")}</label>
                                <Input placeholder={t("repeatNewPassword")} name="repeatNewPassword" onChange={this.onChange} type="password" />
                            </Form.Field>
                        </Form.Group>
                        <Button color='teal' disabled={isInvalid} type="submit" onClick={this.onSubmit}>{t("changePassword")}</Button>            
                    </Form>
                </Segment> */}
                <Segment loading={loading}>
                    <Form onSubmit={this.onHandleSubmit}>
                        <Input type="password" label={t("oldPassword")} placeholder={t("oldPassword")} name="oldPassword" onChange={this.handleChange} value={oldPassword} validators={['required']} errorMessages={['this field is required']} width={4} />
                        <Input type="password" label={t("newPassword")} placeholder={t("newPassword")} name="newPassword" onChange={this.handleChange} value={newPassword} validators={['required','matchRegexp:' + SETTINGS.PASSWORD_VALIDATION_REGEXP]} errorMessages={['this field is required','Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (#?!@$%^&*-)']} width={4} /> 
                        <Input type="password" label={t("repeatNewPassword")} placeholder={t("repeatNewPassword")} name="repeatNewPassword" onChange={this.handleChange} value={repeatNewPassword} validators={['required']} errorMessages={['this field is required']} width={4} />
                        <Button color='teal' disabled={isInvalid} type="submit">{t("changePassword")}</Button>
                    </Form>
                </Segment>
            </div>
        );
  }
}

export default withTranslation()(withFirebase(ChangePasswordForm));
