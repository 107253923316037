import React, { Component } from 'react';
import { compose } from 'recompose';
//import { Redirect } from 'react-router-dom';

import {Form,Input} from 'semantic-ui-react-form-validator'
import { Button, Header, Segment, Grid } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';


import { withAuthorization } from '../Session';
import { withTranslation  } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CountryDropdown,RegionDropdown } from '../Elements/CountryDropdown';
import * as alertModal from "../Elements/AlertMsg";
import i18n from '../../i18n';
import * as ROUTES from '../../constants/routes';
 

const INITIAL_STATE = {
    isEdting: false,
    error: null,
};


class AccountForm extends Component {
    constructor(props) {
        super(props);
        //this.authUser = JSON.parse(localStorage.getItem('authUser'));
        this.authUser = this.props.authUser;

        //JSON.parse(localStorage.getItem('authUser'))
        //let user = this.authUser;
        let user = {
            name : this.props.authUser.name,
            phone : this.props.authUser.phone
        }
        user.address = this.props.authUser.hasOwnProperty("address") ? this.props.authUser.address : {};
        user.name.middleStr = user.name.Middle.length > 0 ? user.name.Middle.join(' ') : '';

        this.state = { ...INITIAL_STATE, user: user};
    }

    componentDidMount() {
        let user = {
            name : this.props.authUser.name,
            phone : this.props.authUser.phone,
        }
        user.address = this.props.authUser.hasOwnProperty("address") ? this.props.authUser.address : {};
        user.name.middleStr = user.name.Middle.length > 0 ? user.name.Middle.join(' ') : '';

        this.setState({
            user: user,
            oldUser: user
        });
        
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onSubmit = (event) => {
        this.onHandleSubmit();
    }

    onHandleSubmit = () => {
        const { t, i18n } = this.props;
        const {isEdting, user} = this.state;
        console.log(user);
        
        user.name.Middle = user.name.middleStr !== '' ? user.name.middleStr.split(',') : [];
        if( i18n.language === 'hu')
            user.fullName = user.name.Last + ' ' + (user.name.middleStr !== '' ? user.name.middleStr + ' ' : '') +  user.name.First;
        else
            user.fullName = user.name.First + ' ' + (user.name.middleStr !== '' ? user.name.middleStr + ' ' : '') +  user.name.Last;

        this.props.firebase.user(this.authUser.uid).set(user, { merge : true}).then(x => {
            alertModal.successAlert(t("title_success"),t("msg_user_account_succesfully_saved"));
            this.props.firebase.auth.currentUser.updateProfile( {displayName: user.fullName} );
            this.setState({
                isEdting: false
            });
        }).catch(error =>{
            console.log(error);
            alertModal.errorAlert(t("title_error"),t("msg_error_user_account_save"));
            this.setState({
                isEdting: false
            });
        });
    };

   /*  onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }; */

    onChange = (event,data) => {
        
        
        let newData = {[data.name]: data.value };
        if( data.hasOwnProperty("prefix") )
        {
            const newPrefixData = {...this.state.user[data.prefix], ...newData};
            newData = { [data.prefix] : newPrefixData};
        }
        
        if(data.type === "dropdown" || data.type === "change")
		{
            const newFields = { ...this.state.user, ...newData };
        	this.setState({ user: newFields });
		}
		else if(data.type === "checkbox")
		{
			const newFields = { ...this.state.user, [data.name]: data.checked };
        	this.setState({ user: newFields });
		}
		else if(data.type === "radio")
		{
			const newFields = { ...this.state.user, ...newData };
        	this.setState({ user: newFields });
		}
		else
		{
			const newFields = { ...this.state.user, ...newData };
        	this.setState({ user: newFields });
		}
	};


    handleEnableEdit = event =>{
        this.setState({ isEdting: true});
    }
    
    handleCancelEdit = event => {
        this.setState({ isEdting: false});
    }

    handleBack = event => {
        const { history } = this.props;
        history.push(ROUTES.LANDING);
    }

    render() {
        const {isEdting,user} = this.state;
        const { t } = this.props;
        console.log(user);


        return (
            <div>
            <Header as="h1" color="teal" dividing>{t("account")}</Header>

            <Form id ="dataForm" onSubmit={this.onSubmit}>
                <Segment>
                    <Grid columns='equal'>
                        <h3>{t("name")}</h3>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Input type="text" label={t("email")} placeholder={t("email")} value={this.props.authUser.email} disabled={true} width={16} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input type="text" prefix="name" label={t("title")} placeholder={t("title")} name="title" onChange={this.onChange} value={user.name.title} disabled={!isEdting} width={16} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input type="text" prefix="name" label={t("firstName")} placeholder={t("firstName")} name="First" onChange={this.onChange} value={user.name.First} validators={['required']} errorMessages={['this field is required']} disabled={!isEdting} width={16} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input type="text" prefix="name" label={t("middleName")} placeholder={t("middleName")} name="middleStr" onChange={this.onChange} value={user.name.middleStr} disabled={!isEdting} width={16} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input type="text" prefix="name" label={t("lastName")} placeholder={t("lastName")} name="Last" onChange={this.onChange} value={user.name.Last} validators={['required']} errorMessages={['this field is required']} disabled={!isEdting} width={16}  />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <h3>{t("phone")}</h3>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Input type="text" prefix="phone" label={t("phoneFix")} placeholder={t("phoneFix")} name="fix" onChange={this.onChange} value={user.phone.fix} disabled={!isEdting} width={16} />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Input type="text" prefix="phone" label={t("phoneMobile")} placeholder={t("phoneMobile")} name="mobile" onChange={this.onChange} value={user.phone.mobile} disabled={!isEdting} width={16}  />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment>
                    <h3>{t("address")}</h3>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <CountryDropdown
                                    type="dropdown"
                                    prefix="address"
                                    label={t("country")}
                                    name='country'
                                    placeholder={t("country")}
                                    validators={['required']} 
                                    errorMessages={['this field is required']}
                                    value={user.address.country}
                                    onChange={this.onChange}
                                    width={16}
                                    disabled={!isEdting}
                                />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <RegionDropdown
                                    type="dropdown"
                                    prefix="address"
                                    label={t("region")}
                                    name='region'
                                    placeholder={t("region")}
                                    validators={['required']} 
                                    errorMessages={['this field is required']}
                                    value={user.address.region}
                                    onChange={this.onChange}
                                    country_code={user.address.country}
                                    width={16}
                                    disabled={!isEdting}
                                />		
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Input type="text" prefix="address" label={t("postalCode")} placeholder={t("postalCode")} name="postalCode" onChange={this.onChange} value={user.address.postalCode} validators={['required']} errorMessages={['this field is required']} width={16} disabled={!isEdting} />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Input type="text" prefix="address" label={t("city")} placeholder={t("city")} name="city" onChange={this.onChange} value={user.address.city} validators={['required']} errorMessages={['this field is required']} width={16} disabled={!isEdting} />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Input type="text" prefix="address" label={t("street")} placeholder={t("street")} name="street" onChange={this.onChange} value={user.address.street} validators={['required']} errorMessages={['this field is required']} width={16} disabled={!isEdting} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>


                <Button color='teal' type="submit" disabled={!isEdting}>{t("save")}</Button>  
                <Button as={Link} primary disabled={isEdting} onClick={this.handleEnableEdit} >{t("edit")}</Button>  
                <Button as={Link} disabled={!isEdting} onClick={this.handleCancelEdit}  >{t("cancel")}</Button> 
                {/* <Button as={Link} onClick={this.handleBack}  >{t("back")}</Button>  */}             
            </Form>
            </div>
        );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withTranslation(),
)(AccountForm);

//export default withTranslation()(withFirebase(AccountForm));
