import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import withGroup from '../Session/withGroup';

//import { Redirect } from 'react-router-dom';

import {Button, Segment, Grid, Header } from 'semantic-ui-react'
import {Form,Input} from 'semantic-ui-react-form-validator'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import { withTranslation  } from 'react-i18next';
import * as ROUTES from '../../constants/routes';
import * as SETTINGS from '../../constants/settings';
import * as alertModal from "../Elements/AlertMsg";

 

const INITIAL_STATE = {
    firstName: '',
    middleName: '',
    lastName: '',
    medicalId: '',
    prevMedicalId: '',
    motherName: '',
    fullName: '',
    birthDate: null
};


class PatientForm extends Component {
    constructor(props) {
        super(props);

        this.patientRef = this.props.firebase.patients();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));

        //JSON.parse(localStorage.getItem('authUser'))
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        if(this.props.match.params.id)
        {
            if(this.state.patient){
                return;
            }
          
            this.setState({ loading: true });
        
            this.unsubscribe = this.props.firebase
            .patient(this.props.match.params.id)
            .onSnapshot(snapshot => {
                const data = snapshot.data();
                this.setState({
                    patient: snapshot.data(),
                    firstName: data.name.First,
                    middleName: data.name.Middle.length > 0 ? data.name.Middle.join(' ') : '',
                    lastName: data.name.Last,
                    fullName: data.fullName,
                    medicalId: data.medicalCardNumber,
                    prevMedicalId: data.medicalCardNumber,
                    motherName: data.motherName,
                    birthDate: data.birthday.toDate(),
                    loading: false,
                });
            });
        }
        fetch(SETTINGS.IPAPI_URL)
            .then(response => response.json())
            .then(data => {
                const location = {
                    country : {
                        code: data.country,
                        name: data.country_name
                    },
                    region: {
                        code: data.region_code,
                        name: data.region
                    },
                    city: data.city,
                    isEU: data.in_eu,
                    continent: data.continent_code,
                    timezone: data.timezone,
                    postalCode: data.postal,
                    ip: data.ip,
                    gps: this.props.firebase.getGeoPoint(data.latitude, data.longitude)
                }
            this.setState({
                location: location
            });
        });
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onSubmit = event => {
        const { history,t } = this.props;
        const {prevMedicalId} = this.state
        let patientData =  this.preparePatienData();

        if( this.props.match.params.id && prevMedicalId === patientData['medicalCardNumber'] )
        {
            this.savePatient(patientData);
        }
        else
        {
            const groupObj = this.props.workgroup;
            this.verifyPatient(groupObj.id, patientData.medicalCardNumber).then(x => {
                if(x)
                {
                    alertModal.errorAlert(t("title_error"),t("msg_patient_already_exist_with_medicalId"));
                    return false;
                }
                else {
                    this.savePatient(patientData);
                }
            })
            .catch(e => {
                console.log(e);
            });
        }
    };

    savePatient = ( patientData) => {
        const { history,t } = this.props;
        this.getDocumentRef().set(patientData, { merge : true}).then(ref => {
            const redirectCallback = () => { history.push(ROUTES.PATIENTS); };
            alertModal.successAlert(t("title_success"),t("msg_patient_succesfully_saved"),redirectCallback);
        }).catch(error =>{
            console.log(error);
            alertModal.errorAlert(t("title_error"),t("msg_error_patient_save"));
        });
    }



    onCancel = () =>{
        const { history} = this.props;
        history.push(ROUTES.PATIENTS); 
    }

    getDocumentRef() {
        if( this.props.match.params.id )
        {
            return this.patientRef.doc(this.props.match.params.id);
        }
        else
        {
            return this.patientRef.doc();
        }
	}

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onDateChange = (event,data) =>{
        this.setState({ [data.name]: data.value });
    };

    onDateBlur = (event,data) =>{
        console.log("blur");
        console.log(event);
        this.setState({ [data.name]: data.value });
    };

    verifyPatient = async( groupId, medicalId) =>{
        let patients = await this.patientRef
                .where("group.id","==",groupId)
                .where("medicalCardNumber","==",medicalId)
                .limit(1)
                .get();
        return patients.size > 0;
    }

    preparePatienData = () => {
        const { i18n } = this.props;
        const patientState = this.state;
        
        let  patientData = {};
        patientData['medicalCardNumber'] = patientState['medicalId'];
        if( patientState['birthDate'] != null && patientState['birthDate'] !== '')
        {
            let birthDate = new Date(patientState['birthDate']);
            birthDate.setHours(0, 0, 0, 0);
            patientData['birthday'] = birthDate;
            patientData['birthdayStr'] = birthDate.toISOString().split("T")[0];
        }
        
        patientData['motherName'] = patientState['motherName'];
        
        

        patientData['name'] = {};
        patientData['name']['First'] = patientState['firstName'];
        patientData['name']['Last'] = patientState['lastName'];
        patientData['name']['Middle'] = patientState['middleName'] !== '' ? patientState['middleName'].split(' ') : [];

        patientData['lc_motherName'] = patientData['motherName'].toLowerCase();
        patientData['lc_name'] = {
            First: patientData['name']['First'].toLowerCase(),
            Last: patientData['name']['Last'].toLowerCase(),
            Middle: patientData['name']['Middle'].length > 0 ? patientData['name']['Middle'].map(x => {return x.toLowerCase();}) : []
        };

        if( i18n.language === 'hu' )
        {
            patientData['fullName'] = patientState['lastName'] + ' ' + patientState['firstName'] + ' ' + ((patientState.hasOwnProperty("middleName") && patientState['middleName'] !== '') ? patientState['middleName'] : '');
        }
        else
        {
            patientData['fullName'] = patientState['firstName'] +  ' ' + ((patientState.hasOwnProperty("middleName") && patientState['middleName'] !== '') ? patientState['middleName'] + ' ' : '') +  patientState['lastName'];
        }

        if( !this.props.match.params.id )
        {
            patientData['date'] =  new Date();
            patientData['user'] = {
                id : this.authUser.uid,
                name : this.authUser.fullName,
                //ref : this.props.firebase.db.collection(`Users`).doc(this.authUser.uid)
            }
            
            patientData['group'] = this.props.workgroup;
    
            patientData['isActive'] = true;


            patientData['examinations'] = [];
            patientData['lastExaminationDate'] = null;
            patientData['registered_location'] = patientState.location;
        }

        return patientData;
    };


    render() {
        const { firstName, middleName, lastName, medicalId ,birthDate, motherName, fullName, location } = this.state;
        const isInvalid = birthDate === null ;
        const { t } = this.props;

        const pageTitle = this.props.match.params.id ? t("edit") + ": " + fullName : t("add_patient");

        return (
            <div>
                <Header as="h1" color="teal" dividing>{pageTitle}</Header>

                <Form id ="dataForm" onSubmit={this.onSubmit}>
                    <Segment>
                    <Grid columns='4'>
                        <Grid.Row>
                            <Grid.Column>
                                <Input type="text" label={t("firstName")} placeholder={t("firstName")} name="firstName" onChange={this.onChange} value={firstName} validators={['required']} errorMessages={['this field is required']} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input type="text" label={t("middleName")} placeholder={t("middleName")} name="middleName" onChange={this.onChange} value={middleName}  />
                            </Grid.Column>
                            <Grid.Column>
                                <Input type="text" label={t("lastName")} placeholder={t("lastName")} name="lastName" onChange={this.onChange} value={lastName} validators={['required']} errorMessages={['this field is required']} /> 
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <label><b>{t("dateOfBirth")}</b></label>
                                <div>
                                    <SemanticDatepicker name="birthDate" onChange={this.onDateChange} value={birthDate} validators={['required']} errorMessages={['this field is required']} width={5} />
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <Input type="text" label={t("motherName")} placeholder={t("motherName")} name="motherName" value={motherName} onChange={this.onChange} validators={['required']} errorMessages={['this field is required']} />                            
                            </Grid.Column>
                            <Grid.Column>
                                <Input type="text" label={t("medicalId")} placeholder={t("medicalId")} name="medicalId"  value={medicalId} onChange={this.onChange} validators={['required']} errorMessages={['this field is required']} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Button color='teal' disabled={isInvalid} type="submit">{t("save")}</Button>
                <Button type="button" negative={true} as={Link} onClick={this.onCancel}  >{t("cancel")}</Button>              
            </Form>
            </div>
        );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withGroup(condition),
  withTranslation(),
)(PatientForm);

//export default withTranslation()(withFirebase(PatientForm));
