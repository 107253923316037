

const rdsParameterColumns = [
	/* {"name": "ID","type": "text", import: false, export: false},
	{"name": "SET_ID","type": "text", import: false, export: false}, */
	{"name": "orderId","type": "number", import: true, export: true},
	{"name": "name","type": "text", import: true, export: true},
	{"name": "density","type": "number",import: true, export: true},
	{"name": "disparity","type": "number",import: true, export: true},
	{"name": "pixelSize","type": "number",import: true, export: true},
	{"name": "bgDisparity","type": "number",import: true, export: true},
	{"name": "fps","type": "number",import: true, export: true},
	{"name": "noise","type": "number",import: true, export: true},
	{"name": "red","type": "color",import: true, export: true},
	{"name": "green","type": "color", import: true, export: true},
	{"name": "black","type": "color", import: true, export: true},
	{"name": "yellow","type": "color", import: true, export: true},
	{"name": "isNoiseExcludeE","type": "bool", import: true, export: true},
	{"name": "isDynamic","type": "bool", import: true, export: true},
	{"name": "isDRDC","type": "bool", import: true, export: true},
	{"name": "schnellenE","type": "number", import: true, export: true},
];

export {rdsParameterColumns};