import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../../../components/Session';
//import { Redirect } from 'react-router-dom';

import { Form, Input, Button, Message, Transition} from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import { withTranslation  } from 'react-i18next';
import readXlsxFile from 'read-excel-file';
import {rdsParameterColumns} from '../RDSParameters/columns'; 



class RDSParameterImport extends Component {
    constructor(props) { 
        super(props);

        this.patientRef = this.props.firebase.patients();

        //JSON.parse(localStorage.getItem('authUser'))
        this.state = {
            fileName: '',
            file: null,
            notification: null
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onHandleImport = event => {
        const { file } = this.state;
        const { t } = this.props;

        const schema = this.getSchema();

        readXlsxFile(file,{schema})
            .then(({rows,errors}) => {
                // `rows` is an array of rows
                // each row being an array of cells.
            /*     console.log(errors);
                console.log(rows); */
                if( errors.length > 0)
                {
                    // parse error
                    let errorList = [];
                    for(let error of errors)
                    {
                        console.log(error);
                        let val = t('line') + ' ' + error.row  + ' ' + t('field') + ' ' + error.column + ' ' + error.error; 
                        console.log(val);
                        errorList.push(val);
                    }
                    this.setState({ 
                        notification: {
                            list : errorList,
                            type : 'error'
                        }
                    });
                }
                else
                {
                    // save data
                    this.importData(rows).then(() =>{
                        let successList = [];
                        successList.push('The RDSParmeters are successfully imoprted');
                        this.setState({ 
                            notification: {
                                list : successList,
                                type : 'success'
                            }
                        });
                    });
                }
            })
            .catch(( error) => {
                console.log('error');
            });

    }
        

    onFileChange = event =>{
        this.setState({ 
            fileName: event.target.value,
            file: event.target.files[0]
        });

        // filename
        console.log('filename ' + event.target.value);
        //file 
        console.log('file ' + event.target.files[0]);
	};
	
	getSchema(){
		let schema = {};
		for( let column of rdsParameterColumns)
		{
			if( column.type === 'text')
			{
				schema[column.name] = { prop: column.name, type: String, required: true};
			}
			else if( column.type === 'number')
			{
				schema[column.name] = { prop: column.name, type: Number, required: true};
			}
			else if( column.type === 'bool')
			{
				schema[column.name] = { 
					prop: column.name,
					required: true,
					parse(value){
						switch (value.toLowerCase ()) {
                            case "1":
							case "true":
                                return true;
                            case "0":
							case "false":
								return false;
							default:
								throw new Error ("Boolean.parse: Cannot convert string to boolean.");
						}
					}
				};
			}
			else if( column.type === 'color')
			{
				schema[column.name] = { 
					prop: column.name,
					type: String, 
					required: true,
					parse(value){
                        try{
                            let color = value.split(' ');
                            return { R: Number(color[0]), G: Number(color[1]), B: Number(color[2])};
                        }
                        catch(e){
                            throw new Error('invalid color');
                        }
					}
				};
			}
		}
		return schema;
	}

    importData = async( items ) => {
        var batchSize = 500;
        while (items.length) {
            var batchItems = items.splice(0, batchSize);
            try {
                const batch = this.props.firebase.db.batch();
                for( let item of batchItems)
                {
                    let data =  this.prepareData(item);
                    let docRef = this.props.firebase.rdsParameters().doc();
                    batch.set(docRef, data,{merge: true});
                }
                batch.commit();
            } catch (error) {
                console.log(error);
            }
        }
    };


    prepareData = (item) => {       
		let data = {};

		for( let column of rdsParameterColumns)
		{
            console.log(column.name);
            if(column.type === "text")
            {
                data[column.name] = "_" + item[column.name].toString().replace(".","_") + "_";
            }
            else
            {
                data[column.name] = item[column.name];
            }
        }
        data['setNameRef'] = this.props.firebase.parameterSets().doc(this.props.setId);
        data['parameterSetId'] = this.props.setId;
        console.log(data);
        return data;
	};
	
	showMessage = (notification) => {
        const { t } = this.props;
        if( notification.type === 'error')
            return <Transition onHide={this.clearNotfication} ><Message onDismiss={this.clearNotfication} header={t('error')} list={notification.list} negative /></Transition>
        else
            return <Transition onHide={this.clearNotfication} ><Message onDismiss={this.clearNotfication} header={t('success')} list={notification.list} positive /></Transition>
    };

    clearNotfication = () => {
        this.setState({ 
            notification: null
        });
    };

    render() {
        const { fileName, notification } = this.state;
        const isFileSelected = fileName === '';
        const { t } = this.props;

        return (
            
            <Form>
                {notification && (
                    this.showMessage(notification)
                )}
                <Form.Group inline>
                    <Form.Field>
                        <label>{t("Please_select_the_file")}</label>
                        <Input placeholder={t("fileName")} type='file' name="fileName" onChange={this.onFileChange} />
                    </Form.Field>
                    <Form.Field>
                        <Button color='teal' disabled={isFileSelected} type="submit" onClick={this.onHandleImport}>{t("Import")}</Button>
                    </Form.Field>
                </Form.Group>                         
            </Form>
        );
  }
}

const condition = authUser => !!authUser;
export default compose(
    withAuthorization(condition),
    withTranslation(),
  )(RDSParameterImport);
