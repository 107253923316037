import React from 'react';
import { compose } from 'recompose';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import PatientDetail from './PatientDetail';
import PatientList from './PatientList';

import * as ROUTES from '../../constants/routes';
import PatientForm from './PatientForm';

const PatientPage = () => (
  <div>
    <Switch>
      <Route exact path={ROUTES.PATIENTS} component={PatientList} />
      <Route exact path={ROUTES.PATIENT_ADD} component={PatientForm} />
      <Route exact path={ROUTES.PATIENT_EDIT} component={PatientForm} />
      <Route path={ROUTES.PATIENT_DETAIL} component={PatientDetail} />
    </Switch>
  </div>
);

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(PatientPage);
