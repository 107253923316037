export const PACKAGE_LIST = "/admin/packages";
export const PACKAGE_ADD = "/admin/packages/add";
export const PACKAGE_EDIT = "/admin/packages/edit/:id";

export const PARAMETER_SET_LIST = "/admin/parameter-sets";
export const PARAMETER_SET_ADD = "/admin/parameter-sets/add";
export const PARAMETER_SET_EDIT = "/admin/parameter-sets/edit/:id";

export const RDS_PARAMETER_LIST = "/admin/rds-parameters";
export const RDS_PARAMETER_ADD = "/admin/rds-parameters/add";
export const RDS_PARAMETER_EDIT = "/admin/rds-parameters/edit/:id";