import React from 'react';
import InvoiceDataForm from './InvoiceDataForm';
import SummaryAndConfirmForm from './SummaryAndConfirmForm';
import UserDataForm from './UserDataForm';
import VerificationEmailForm from './VerificationEmailForm';

const StepPage = ({step, values, nextStep, handlePrices, handleChange, handleGroupAddition, handleVerifyEmail }) => {
    switch(step) {
        case 0:
            return <VerificationEmailForm nextStep={nextStep} handleChange={handleChange} values={values} handleVerifyEmail={handleVerifyEmail} /> 
        case 1:
            return <UserDataForm nextStep={nextStep} handleChange={handleChange} values={values} handleGroupAddition={handleGroupAddition} />
        case 2:
            return <InvoiceDataForm nextStep={nextStep} handleChange={handleChange} values={values} />
        case 3:
            return <SummaryAndConfirmForm nextStep={nextStep} handleChange={handleChange} values={values} handlePrices={handlePrices} />
        default:
            return ""
    }
};

export default StepPage;