import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { Button, Icon, Item, Header, Loader, Dimmer} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next';

import * as qs from 'query-string';
import * as base64_helper from "../Util/base64-helper";
import * as alert from "../Elements/AlertMsg";
import * as ROUTES from '../../constants/routes';
import {SimplePayLogo} from '../Footer';
import PurchaseFormModal from '../Purchase/PurchaseFormModal';

//const paragraph = <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />



class PackageList extends Component {

	constructor(props) {
		super(props);

		this.authUser = JSON.parse(localStorage.getItem('authUser'));

		this.state = {
			loading: false,
			pkgItem: {},
			activeDimmer: false,
			openPurchaseModal: false,
			packages: [],
			purchase_name: this.authUser !== null ? this.authUser.fullName : "" ,
			purchase_email: this.authUser !== null ? this.authUser.email : "" ,
			groupOptions: this.authUser !== null ? this.authUser.groups.map(x => { return  {key: x.id, text: x.name, value: x.id}; }) : [],
			termsAgree: false,
			...props.location.state
		};
	}

	componentDidMount() {
		this.checkSimplePayResponse();
		//get items
		//console.log(this.authUser);
		this.getItems();
	}

	componentWillUnmount() {
		//this.unsubscribe();
	}
	
	checkSimplePayResponse() {
		const { history , t, i18n } = this.props;
		if(this.props.location.search)
		{
			const params = qs.parse(this.props.location.search);
			console.log(params);
			let spr = base64_helper.decode(params.spr);
			console.log(spr);
			if( spr.data.hasOwnProperty("lang"))
			{
				i18n.changeLanguage(spr.data.lang);
			}


			if( spr.code === 200) // success payment, user have already account, is logged in
			{
				let sucsMsg = "Successful transaction.<br/>SimplePay transaction identifier: <b>[transactionId]</b>";
				sucsMsg = t("msg_successfull_simplepay_payment").replace('[transactionId]',spr.data.spTransactionId);

				const redirectCallback = () => { history.push(ROUTES.PURCHASES); };
				alert.successAlert(t("title_success"),sucsMsg, redirectCallback);
				//history.push(ROUTES.PURCHASES);
				//notify.success(t(sucsMsg));
			}
			else if( spr.code === 201) // success payment, user have already account, is not logged in
			{
				const infoRegisterCallback = () => { alert.infoAlert(t("title_info"),t("msg_already_account_login"));};
				alert.successAlert(t("title_success"),t("msg_payment_successfull"),infoRegisterCallback);
				//notify.success(t("msg_payment_successfull"));
				//notify.info(t("msg_already_account_login"));
			}
			else if( spr.code === 205) // // success payment , user haven't account NEW user
			{
				let sucsMsg = "Successful transaction.<br/>SimplePay transaction identifier: <b>[transactionId]</b>";
				sucsMsg = t("msg_successfull_simplepay_payment").replace('[transactionId]',spr.data.spTransactionId);

				const redirectCallback = () => { history.push({ pathname: ROUTES.REGISTER_BY_REQUEST, state: { paymentRequestId: spr.data.paymentRequestId }}); };
				const infoRegisterCallback = () => { alert.infoAlert(t("title_info"),t("msg_register_to_the_system"),redirectCallback)};
				alert.successAlert(t("title_success"),sucsMsg,infoRegisterCallback);
			}
			else if( spr.code === 511) // // timeout payment
			{
				alert.warningAlert(t("title_warning"),t("msg_maximum_time_exceeded"));
			}
			else if( spr.code === 512) // // fail payment
			{
				let notMsg = "Failed transaction.SimplePay transaction identifier: [transactionId] <br/> Please check if the details provided during the transaction are correct.<br/>If all of the details were provided correctly, please contact the bank that issued your card <br/> in order to investigate the cause of the rejection.";
				notMsg = t("msg_failed_simplepay_payment").replace('[transactionId]',spr.data.spTransactionId);
				alert.errorAlert(t("title_error"),notMsg);
			}
			else if( spr.code === 513) // // cancel payment
			{
				alert.warningAlert(t("title_warning"),t("msg_canceled_payment"));
			}
			else
			{
				alert.errorAlert(t("title_error"),t("msg_error_in_purchase_process"));
			}
		}
	}


	getItems( ) {
		this.setState({ loading: true });
		let packageQuery = this.props.firebase.packages();

		if(this.authUser !== null && !!this.authUser.isAdmin)
		{
			packageQuery = packageQuery.where("isActive","==", true);
		}
		else
		{
			packageQuery = packageQuery.where("isPublished","==", true);
		}

		this.unsubscribe = packageQuery
		.onSnapshot(snapshot => {
			let packages = [];

			snapshot.forEach(doc =>
					packages.push({ ...doc.data(), id: doc.id }),
			);

			this.setState({
					packages,
					loading: false
				});
		});
		
	}

	onFinishPayment() {
		this.setState({ 
			activeDimmer: true
		});
	}

	onClosePurchaseModal() {
		this.setState({ 
			openPurchaseModal: false,
			packageItem : null
		});
	}

	onOpenPurchaseModal(packageItem) {
		//console.log(packageItem);
		this.setState({ 
			openPurchaseModal: true,
			packageItem : packageItem,
			activeDimmer: false
		});
	}

	render() {
		const { packages, loading, activeDimmer } = this.state;
		const { t } = this.props;
		//const isInvalid =  !this.state.termsAgree;

		let groupDropdown = "";
		if(this.authUser !== null)
		{
				/* groupDropdown = <Form.Group>
					<Form.Field inline required>
							<label>{t("groupName")}</label>
							<Dropdown
								name ="purchase_group"
								options={this.state.groupOptions}
								placeholder={t("chooseGroup")}
								search
								selection
								allowAdditions
								additionLabel={t("groupName")}
								value={this.state.selected_groupId}
								onAddItem={this.handleAddition}
								onChange={this.onGroupDropdownChange}
								/>
					</Form.Field>
				</Form.Group>; */
				/* groupDropdown = <Dropdown
					label={t("groupName")}
					name='purchase_group'
					placeholder={t("chooseGroup")}
					search
					selection
					allowAdditions
					additionLabel={t("groupName")}
					options={this.state.groupOptions}
					value={this.state.selected_groupId}
					onChange={this.onGroupDropdownChange}
				/>;
 */

		}


		return (
			<div>
				<Header as="h1" color="teal" dividing>{t("packages")}</Header>
				<Dimmer active={activeDimmer}>
					<Loader size='large'>
						{t("preparing_to_payment")}
						<div>
							<SimplePayLogo size="medium" />
						</div>
					</Loader>
				</Dimmer>

				{loading ? (
					<Loader active inline />
				) : (
					<Item.Group divided>
					{packages.map((pkgItem, i) => (
							<Item key={pkgItem.id}>
									<Item.Image src='/product_image.jpg' />
									<Item.Content>
											<Item.Header as='a'>{pkgItem.name}</Item.Header>
											<Item.Meta>
												<span className='cinema'>{pkgItem.unitPrice} €</span>
											</Item.Meta>
											<Item.Description>{pkgItem.description}</Item.Description>
											<Item.Extra>
												<Button primary floated='right' onClick={() => this.onOpenPurchaseModal(pkgItem)}>{t("purchase")}<Icon name='right chevron' /></Button>
											</Item.Extra>
									</Item.Content>
							</Item>
						))}
					</Item.Group>
				)}
				{
					this.state.openPurchaseModal ? (
						<PurchaseFormModal open={this.state.openPurchaseModal} packageItem={this.state.packageItem} onClose={() => this.onClosePurchaseModal()} onFinish={() => this.onFinishPayment()} history={this.props.history} />
					) : ("")
				}
				
			</div>
		);
	}
}

export default withTranslation()(withFirebase(PackageList));