import React, { Component } from 'react';
import {Segment, Label, Grid} from 'semantic-ui-react';
import {Input} from 'semantic-ui-react-form-validator';

class ColorInput extends Component {

	constructor(props) {
		super(props);

		this.state = {
			color : {}
		};

	}

	componentDidMount() {
		const color = { R: this.props.R, G: this.props.G, B: this.props.B};
		this.setState({ color: color });
	}

	componentWillUnmount() {
	}

	onChange = (event,data) => {
		const newColor = { ...this.state.color, [data.name]: Number(data.value) };
		this.setState({ color: newColor });
		const e = {};
		const dataValue ={
			name: this.props.name,
			value:newColor
		}
		this.props.onChange(e,dataValue);
	};

	render() {
		return <Segment>
			<h5>{this.props.label}</h5>
			<Grid>
				<Grid.Row>
					<Grid.Column width={5}>
						<Input type="text" label="R" placeholder="R" name="R" onChange={this.onChange} value={this.state.color.R} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} width={16} />
					</Grid.Column>
					<Grid.Column width={5}>
						<Input type="text" label="G" placeholder="G" name="G" onChange={this.onChange} value={this.state.color.G} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} width={16} />
					</Grid.Column >
					<Grid.Column width={5}>
						<Input type="text" label="B" placeholder="B" name="B" onChange={this.onChange} value={this.state.color.B} validators={['required','isNumber']} errorMessages={['this field is required','Should be number']} width={16} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	}
}

export default ColorInput;